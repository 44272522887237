@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.guest-profile-view {
  max-width: 940px;
  margin: 50px auto;

  &__left-col{
    padding-right: 50px;
  }

  &__img {
    max-width: 136px;
    margin-bottom: 20px;
  }

  &__name {
    color: white;
    line-height: 1;
    font-size: 20px;
    font-weight: 700;
    text-transform: none;
    margin-bottom: 6px;
    display: flex;
    align-items: flex-start;
    opacity: 0.9;
  }

  &__edit-icon {
    margin-left: 8px;
    cursor: pointer;
  }

  &__blurb {
    color: white;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.31;
    max-width: 300px;
    margin-bottom: 12px;
    word-break: break-all;
  }

  &__timestamp {
    color: $color-grey-5;
    font-size: 16px;
    font-weight: normal;
  }

  &__social-wrap {
    margin: 25px 0;
  }

  &__social-item {
    color: $guest-room-desc-color;
    font-size: 16px;
    margin-bottom: 14px;
    display: flex;
    align-items: flex-start;
    word-break: break-all;
    padding-right: 5px;
    justify-content: space-between;
    text-decoration: underline;
  }

  &__social-img {
    margin-right: 10px;
    max-width: 32px;
    width: 32px;
    aspect-ratio: 1/1;
  }

  &__visibility-img-wrap{
    border: 1px solid rgba(white, 0.3);
    border-radius: 4px;
    padding: 7px 10px;
    line-height: 1;
  }

  &__visibility-img{
    max-width: 17px;
  }

  &__followers-wrap{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  
  &__followers-link{
    border: 1px solid $color-primary;
    border-radius: 8px;
    padding: 10px 14px;
    color: $color-primary;
    font-size: 18px;
    font-weight: 500;
    min-width: 119px;
    width: 31%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__bio {
    color: $guest-room-desc-color;
    font-size: 16px;
    line-height: 1.56;
    margin-bottom: 32px;
  }

  &__btns-wrap{
    display: flex;
    align-items: flex-start;
  }

  &__icon-btn{
    height: 50px;
    width: 160px;
    border-radius: 25px;
    font-size: 18px;
    font-weight: 500;
    justify-content: center;
  }
  
  &__icon-btn-edit{
    width: 128px;
    margin-right: 34px;
  }

  &__subheading {
    color: $guest-old-rooms-heading;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 8px;
    background: $guest-old-rooms-heading-bg;
    padding: 12px 10px;
  }

  &__list {
    margin-bottom: 22px;
    max-height: 365px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 3px;
      border-radius: 1.5px;
      background-color: rgba($color-light-grey-3, 0.4);
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 16px 0 0 0;
    cursor: pointer;

    @include mq('sm', max) {
      flex-wrap: wrap;
    }

    &:hover .guest-profile-view__room-name{
      color: $color-primary;
      opacity: 1;
    }
    
    &:hover .guest-profile-view__btn-outline{
      display: block;
    }
  }

  &__room-time {
    color: white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__room-info {
    display: flex;
    align-items: flex-start;

    @include mq('sm', max) {
      margin-bottom: 15px;
    }
  }

  &__org-img {
    max-width: 69px;
    margin-right: 13px;
  }

  &__room-name {
    color: $guest-profile-board-name;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.17;
    text-transform: none;
    margin-bottom: 5px;
    max-width: 325px;
    transition: all 0.2s ease-in-out;
    opacity: .5;
  }

  &__board-name {
    color: white;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.56;
    text-transform: none;
    opacity: .4;
    word-break: break-all;
  }

  &__board-name-head {
    font-size: 22px;
    font-weight: bold;
    line-height: 1.14;
    color: $guest-profile-board-name;
    text-transform: none;
    margin-bottom: 5px;
    max-width: 325px;
  }

  &__org-name {
    color: $guest-dashboard-board-color;
    font-size: 16px;
    font-weight: bold;
    line-height: .95;
    text-transform: none;
    margin-bottom: 4px;
  }

  &__board-members {
    color: $guest-dashboard-board-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.79;
    text-transform: none;
  }

  &__btn-outline {
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid $color-primary;
    background: transparent;
    border-radius: 20px;
    padding: 0px 11px;
    margin-bottom: 16px;
    width: 140px;
    height: 35px;

    transition: all 0.2s ease-in-out;
    display: none;
  }

  &__view-all{
    color: $color-primary;
    font-size: 18px;
    text-decoration: underline;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 32px;
    display: block;
  }
}
