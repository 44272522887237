@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';
.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    @include mq("sm", max) {
        overflow: visible;
    }
}

.carousel-content {
    display: flex;
    transition: all 0.5s ease-in-out;
    // -ms-overflow-style: none;  /* hide scrollbar in IE and Edge */
    // scrollbar-width: none;  /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

.carousel-content-web{
    @include mq("sm", max) {
        display: none;
    }
}
.carousel-content-mob{
    display: none;
    @include mq("sm", max) {
        display: flex;
    }
}

@media (hover: none) and (pointer: coarse) {
    .left-arrow,
    .right-arrow {
        display: none;
    }
}

.carousel-content.show-2 > * {
    width: 50%;
}

.carousel-content.show-3 > * {
    width: calc(100% / 3);
}

.carousel-content.show-4 > * {
    width: calc(25% - 20px);
}


.room-invite__carousel {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: 40px;
    position: relative;
    padding-bottom: 14px;
    .carousel__nav {
        position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 38%;
    }
    .carousel__arrow {
        cursor: pointer;
        position: absolute;
        top: 50%;
    }
    .carousel__arrow--right {
        right: -25px;
    }
    .carousel__arrow--left {
        transform: rotate(180deg);
        left: -31px;
    }
}

@media screen and (max-width: 575px) {
    .guestroom_wrapper {
        padding-top: 37px;
    }
    .guestroom_logo {
        margin-bottom: 29px;
    }
    .roomdetail__hero {
        .hero__cover {
            max-width: 414px;
            height: 207px;
            object-fit: cover;
        }
    }
    .roomdetail__description_wrapper {
        padding: 20px 20px 0;
    }
    .room-invite__carousel {
        margin-top: 32px;
        justify-content: flex-start;
        
    }
    .room-invite__carousel__item {
        //   min-width: 200px;
        margin: 0;
    }
    .guestroom__information {
        margin-bottom: 16px;
    }
    .attendees_head {
        display: none;
    }
    .attendees__list {
        margin-top: 0;
        max-height: unset;
        overflow-y: auto;
        padding: 0 89px 0 20px;
    }
    .guestroom__leavewrapper {
        margin-top: 40px;
        border-top: none;
        padding-top: 0;
        text-align: center;
        margin-bottom: 30px;
        .btn__themeoutline {
            width: 314px;
        }
    }
    .leaveguestroom__modal {
        .modal-dialog {
            align-items: flex-end;
            margin: 0;
        }
        .modal-content {
            padding: 40px 50px 50px 50px;
        }
        .modal__desc {
            display: none;
        }
        .modal__footer {
            margin-top: 29px;
            flex-wrap: wrap;
            .btn__themeoutline {
                display: block;
                width: 100%;
                order: 2;
                margin: 0;
            }
            .btn__themefilled {
                display: block;
                width: 100%;
                margin: 0 0 25px 0;
                order: 1;
            }
        }
    }
}
