@import "../../../assets/sass/helpers/variables";

.register_msg-alignment {
    text-align: center;
}

.guest-register {
    position: relative;

    &__btn-register {
        max-width: 350px;
        width: 100%;
        margin: 50px auto 40px;
    }

    &__need-help {
        font-size: 16px;
        color: $color-primary;
        line-height: normal;
        font-weight: 600;
        opacity: 0.9;
        transition: $transition-3ms;
        text-decoration: underline;

        &:hover {
            text-decoration: underline;
            color: $color-primary;
        }
    }


    .form-group--badge {
        position: relative;

        input {
            padding-right: 45px;
        }
    }

    .form__badge {
        font-size: 12px;
        color: #fff;
        opacity: .5;
        font-weight: 700;
        line-height: normal;
        position: absolute;
        right: 13px;
        bottom: 12px;
    }

}

.guest-register__block {
    min-width: 460px;
    max-width: 460px;
    margin: 0 auto;
    margin-bottom: 60px;
    margin-top: 50px;

    @media screen and (max-width: 525px) {
        max-width: 340px;
        min-width: 340px
    }

}

.br__register-form-wrapper {
    .form-group--file {
        margin-bottom: 24px;
    }
}

.role__list {
    padding-left: 20px !important;
}

.role__list__item {
    list-style: disc;
}

.executiveproducer__popover {
    min-width: 320px;
}