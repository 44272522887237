@import "../../../assets/sass/helpers/variables";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px black inset;
  box-shadow: 0 0 0 50px black inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}
.br__register-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
.br__register-block {
  min-width: 350px;
  max-width: 350px;
  margin: 0 auto;
  margin-bottom: 60px;
  margin-top: 50px;
  @media screen and (max-width: 525px){
    max-width: 340px;
    min-width: 340px
  }
}
.br__register-logo {
  max-width: 330px;
  margin-bottom: 58px;
  @media screen and (max-width: 525px) {
    max-width: 314px;
    margin-bottom: 40px;
  }
}
.br__register-need-help {
  font-size: 16px;
  color: $color-primary;
  line-height: normal;
  font-weight: 600;
  opacity: 0.9;
  transition: $transition-3ms;
  &:hover {
    text-decoration: none;
    color: $color-primary;
  }
}
.br__btn-register {
  display: block;
  width: 100%;
  border-radius: 25px;
  margin: 52px 0 40px;
  font-size: 18px;
  height: 40px;
}
.br__register-form-wrapper{
  .form-group--file{
    margin-bottom: 24px;
  }
}
.br__register-disclaimer{
  color: #fff;
  font-size: 12px;
  opacity: 0.8;
  font-weight: 400;
  position: absolute;
  bottom: 10px;
}
.role__list{
  padding-left: 20px;
}
.role__list__item{
  list-style: disc;
}
.executiveproducer__popover{
  min-width: 320px;
}