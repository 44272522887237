@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.theme-modal.image-library-modal {
  .modal-dialog {
    min-width: 650px;
    @include mq("sm", max) {
      min-width: auto;
    }
  }

  .modal-content {
    background: black;
    box-shadow: 0 1px 5px 0 rgba(black, 0.15);
    padding: 40px 40px 42px 40px;
    position: relative;
    @include mq("sm", max) {
      padding: 20px;
    }
  }

  .modal-footer{
    border-top: 1px solid rgba(white, .24);
  }

  .theme-modal__body {
    padding: 0;
  }

  .br__create-organization-icon-check {
    max-width: 60px;
    display: block;
    margin: 0 auto;
  }

  .theme-modal-subheading {
    font-size: 24px;
    color: $color-white;
    margin-top: 16px;
    text-transform: none;
    text-align: center;
  }

  .br__create-organization-icon-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .nav-tabs{
    border-bottom: 1px solid #191919;
  }

  .nav-tabs .nav-link{
    background: transparent;
    color: #6c6c6c;
    border: none;
    font-size: 20px;
    font-weight: 500;
  }
  .nav-tabs .nav-link.active{
    color: white;
    border-bottom: 3px solid $color-primary;
  }

  .image-row{
    max-height: 410px;
    overflow-y: auto;
    
    /* width */
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 1.5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #282828;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #282828;
    }
  }

  .image-label{
    color: white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 6px;
  }

  .image-search{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $color-white;
    min-height: 24px;
    padding: 6px 8px;
    background: transparent;
    outline: none;
    border-radius: 4px;
    border: solid 1px rgba($color-white, 0.3);

    &::placeholder {
      opacity: 0.8;
    }

    &:focus,
    &.focus {
      outline: none;
      box-shadow: none;
      color: $color-white;
      background-color: transparent;
      border-color: $color-primary;
      caret-color: $color-primary;

    }
  }

  .btn__link{
    color: $color-primary;
  }

  .image-lib__not-found {
    font-size: 20px;
    text-align: center;
    margin-bottom: 15px;
  }
}

.library-img{
  max-width: 100%;
  max-height: 178px;
  object-fit: contain;
  margin-bottom: 5px;
  cursor: pointer;
  border: 2px solid transparent;

  &--active{
    border: 2px solid $color-primary;
  }
}

.library-credits{
  font-size: 14px;
  color: white;
  opacity: 0.8;
}

.library-link{
  text-decoration: underline;
}

// for blur backgrouond when modal is open
.modal-open {
  .layout {
    background-color: rgba($card-body-color, 0.769);
    filter: blur(3px);
  }
}