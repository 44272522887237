@import '../../../assets/sass/helpers/variables';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px black inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.br__guest-login-left-col {
  border-right: 1px solid $color-grey-4;

  @media screen and (max-width: 525px) {
    border-bottom: 1px solid rgba($color-grey-4, 0.3);
    padding-bottom: 24px;
    margin-bottom: 29px;
  }
}

.br__guest-login-wrapper {
  max-width: 1094px;
  margin: 90px auto 30px;

  @media screen and (max-width: 525px) {
    margin: 35px 0 45px;
  }
}

.br__guest-login-block {
  margin-left: 107px;

  @media screen and (max-width: 525px) {
    margin-left: 0;
    padding: 0 20px;
  }

  .form-group--file {
    @media screen and (max-width: 525px) {
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .form__uploadlogo {
    width: 250px;
    height: 250px;
    justify-content: center;
    margin-bottom: 0px;

    .linkcolor {
      margin-bottom: 0;
    }
  }

  .br__form-label {
    @media screen and (max-width: 525px) {
      display: none;
    }
  }

  .br__form-control {
    @media screen and (max-width: 525px) {
      border: none;
      border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    }
  }

  textarea {
    @media screen and (max-width: 525px) {
      height: 100%;
    }
  }
}

.br__guestlogin-logo {
  max-width: 276px;
  margin: 0 auto 70px;
  display: block;

  @media screen and (max-width: 525px) {
    max-width: 314px;
    margin-bottom: 29px;
  }
}

.br__btn-join-room {
  width: 100%;
  max-width: 143px;
  border-radius: 25px;
  margin: 16px 0 0;
  font-size: 18px;
  height: 50px;

  @media screen and (max-width: 525px) {
    max-width: 314px;
    margin: 26px auto 0;
    display: block;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: black;
  opacity: 1;
}

.guest-mb-0 {
  margin-bottom: 0;
}

.optional-tag{
  color: $room-invite-role-color;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 12px;
}