@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';


.create-private-room {
  max-width: 460px;
  padding-top: 32px;
  margin: 0 auto;

  &__heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.43;
    color: $color-light-grey-1;
    padding: 8px 17px;
    text-transform: uppercase;
    background: $color-dark-3;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba($color-white, 0.5);
  }

  &__form {
    margin: 24px 0 50px;

    .form-group--badge {
      position: relative;

      input {
        padding-right: 45px;
      }
    }


    .form-group {
      margin-bottom: 24px;

      option {
        background: black;
      }
    }

    .form-label {
      font-size: 14px;
      color: $color-white;
      text-transform: uppercase;
      font-weight: 700;
      line-height: normal;
      opacity: 0.5;
      margin-bottom: 6px;
      display: block;
    }

    .form-control {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      color: $color-white;
      min-height: 24px;
      padding: 6px 8px;
      background: transparent;
      outline: none;
      border-radius: 4px;
      border: solid 1px rgba($color-white, 0.3);

      &::placeholder {
        opacity: 0.8;
      }

      &:focus,
      &.focus {
        outline: none;
        box-shadow: none;
      }
    }

    .form__badge {
      font-size: 12px;
      color: $color-white;
      opacity: 0.5;
      font-weight: 700;
      line-height: normal;
      position: absolute;
      right: 13px;
      bottom: 12px;
    }

    .form__textarea {
      min-height: 168px;
    }

  }

  &__date-time-wrapper {
    display: flex;
    align-items: flex-start;

    @include mq('sm', max) {
      flex-wrap: wrap;
    }
  }
}

.form-group--date {
  margin-right: 20px;

  input {
    min-width: 220px;
    text-transform: uppercase;
    position: relative;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 7px;
    top: 6px;
    background: url(../../../../assets/images/ic-calendar.svg) no-repeat;
    transform: rotate(180deg);
  }
}

.form-group--time {
  margin-right: 10px;
  position: relative;

  input {
    min-width: 105px;
    user-select: none;
  }

  &:nth-last-child(1) {
    margin-right: 0;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    position: absolute;
    right: 0;
    width: calc(100% - 6px);
    user-select: none;
  }
}


.comodal__footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 24px 0 0 0;
  border-top: 1px solid rgba($color-white, 0.24);
  margin-top: 32px;
  flex-wrap: wrap;

  .br__btn-theme-filled {
    min-width: 144px;
  }

  .btn__link {
    color: $color-primary;
  }
}

// modal css
.theme-modal.guest-private-room-modal {
  .modal-dialog {
    max-width: 518px;
    height: 317px;
  }

  .modal-content {
    background: black;
    box-shadow: 0 1px 5px 0 rgba(black, 0.15);
    padding: 37px 70px 55px 87px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    height: 317px;
  }

  .theme-modal__body {
    padding: 0;
  }

  .br__create-organization-icon-check {
    max-width: 60px;
    display: block;
    margin: 0 auto;
  }

  .theme-modal-subheading {
    font-size: 24px;
    color: $color-white;
    margin-top: 22px;
    text-transform: none;
    text-align: center;
    font-weight: 400;
  }

  .br__create-organization-icon-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

// for blur backgrouond when modal is open
.modal-open {
  .layout {
    background-color: rgba($card-body-color, 0.769);
    filter: blur(3px);
  }
}