//=======================================================
// -Global style start
//=======================================================

// Default style start
*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  box-sizing: border-box;
  font-family: $font-default;
  background: black;
  color: $color-white;
  min-height: 100vh;
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  
  // Media Query max width Usage
  @include mq("md", max) {
    font-size: 14px;
  }
  // Media Query min max width Usage
  @include mq-only("md", "xl") {
    font-size: 15px;
  }
  // Media Query min width Usage
  @include mq("xl", min) {
    font-size: 16px;
  }
}

// Remove list styles on ul, ol elements with a class attribute
ul[class],
ol[class] {
  padding-left: 0;
  list-style: none;
}

%headings {
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1;
  margin-top: 0;
}

@for $i from 1 through 6 {
  h#{$i},
  .h#{$i} {
    @extend %headings;
  }
}

input,select,input:focus,textarea,textarea:focus,button:focus {
  outline: none;
}

h1,h2,h3,h4,h5,h6,p,span,ul,li,a,label {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
}

ul,li {
  list-style: none;
}

a:hover,a:focus {
  text-decoration: none;
}

.word-break-all{
  word-break: break-all;
}

.opacity-50{
  opacity: 0.5;
}

.cursor-pointer{
  cursor: pointer;
}
//=======================================================
// -Global style  end
//=======================================================