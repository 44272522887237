@import "../../../assets/sass/helpers/variables";

.board-listing__cel {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $color-light-grey-1;
    border-top: none;
    padding-top: 11px;
    p {
        margin-bottom: 8px;
    }
    .board-listing--truncate{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 2px;
    }
    &--producer{
        width: 254px;
    }
    &--director{
        width: 292px;
    }
}
.board-listing__cel--dropdown {
    text-align: right;
    padding-right: 47px;
}
.board-listing__cel--width-30 {
    width: 30%;
}