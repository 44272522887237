@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.room-detail__img-wrap {
    position: relative;
    margin-bottom: 40px;
    max-width: 391px;

    @include mq('sm', max) {
        max-width: 100%;
    }
}

.room-detail__img-hero {
    max-width: 391px;

    @include mq('sm', max) {
        width: 100%;
        max-height: 208px;
        object-fit: cover;
    }
}

.room-detail__img-org {
    position: absolute;
    right: 18px;
    max-width: 67px;
    bottom: -15px;

    @include mq('sm', max) {
        display: none;
    }
}

.room-detail__info {
    @include mq('sm', max) {
        padding: 0 20px;
    }
}

.room-detail__info-inner {
    @include mq('sm', max) {
        display: flex;
        margin-bottom: 20px;
    }
}

.room-detail__img-org--mob {
    display: none;

    @include mq('sm', max) {
        display: block;
        max-width: 95px;
        margin-right: 10px;
    }
}

.room-detail__name {
    font-size: 24px;
    opacity: 0.9;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
    color: $color-white;
    text-transform: inherit;
}

.room-detail__board-name {
    color: $color-primary;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 8px;
}

.room-detail__time {
    color: $color-white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 25px;

    @include mq('sm', max) {
        margin-bottom: 15px;
    }
}

.room-detail__desc {
    color: $btn-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    word-break: break-word;
}

.room-detail__truncate-4 {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.room-detail__negate-truncate {
    display: block;
    overflow: auto;
}

.room-detail__read-more {
    cursor: pointer;
    color: $color-light-grey-4;
    font-size: 16px;
    font-weight: 700;
    text-decoration: underline;
    margin-top: 10px;
}

.room-detail__speakers {
    margin-top: 30px;

    @include mq('sm', max) {
        padding: 0 20px;
    }
}

.room-detail__speaker-heading {
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    opacity: 0.8;
    color: $color-white;

    @include mq('sm', max) {
        display: none;
    }
}

.room-detail__speakers-list {
    margin-top: 20px;
    padding-right: 15px;

    &--add-height {
        max-height: 276px;
        overflow-y: auto;
    }
}

.room-detail__speakers-list::-webkit-scrollbar {
    width: 3px;
}

.room-detail__speakers-list::-webkit-scrollbar-track {
    background: transparent;
}

.room-detail__speakers-list::-webkit-scrollbar-thumb {
    width: 3px;
    border-radius: 1.5px;
    background-color: rgba($color-light-grey-3, 0.4);
}

.room-detail__speaker__role {
    opacity: 0.8;
    font-family: Lato;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.room-detail__link-inner {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex-wrap: wrap;
}

.room-detail__link-input {
    margin-right: 15px;
    max-width: 298px;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    min-height: 24px;
    padding: 6px 8px;
    background: transparent;
    outline: none;
    border-radius: 4px;
    border: solid 1px rgba(white, 0.3);

    &::placeholder {
        opacity: 0.8;
    }

    &:focus,
    &.focus {
        outline: none;
        box-shadow: none;
        background: transparent;
        border: solid 1px rgba(white, 0.3);
        color: inherit;
    }

    @include mq('xl-14', max) {
        max-width: 270px;
    }
}

.room-detail__link-label {
    opacity: 0.5;
    font-size: 14px;
    font-weight: bold;
    color: white;
    margin-bottom: 6px;
    text-transform: uppercase;
}

.room-detail__link-tagline {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    color: $data-label-color;
    margin-bottom: 16px;
}

.room-detail__icon-btn {
    position: relative;

    .tool-msg {
        display: none;
    }

    &:hover {
        .tool-msg {
            display: block;
            font-size: 11px;
            font-weight: 500;
            border-radius: 3px;
            color: black;
            background: white;
            width: 118px;
            position: absolute;
            top: 0;
            left: calc(100% - 6px);
            z-index: 100;
            padding: 3px 10px;
        }
    }
}

.room-detail__lounge-icon-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.room-detail__lounge-icon {
    margin-right: 7px;
    max-width: 24px;
}

.room-detail__lounge-text {
    color: $data-label-color;
    font-size: 14px;
    font-weight: 700;
    text-transform: none;
    line-height: 1;
    padding-top: 3px;
}