@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.room-invite__card {
  border-radius: 8px;
  background: rgba($color-white, 0.1);
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid transparent;
  @include mq("sm", max) {
    // margin-right: 16px;
    width: 85%;
  }
}

.room-invite__card-header {
  min-height: 183px;
  position: relative;
  border-radius: 8px 8px 0 0;
}

.room-invite__bg-img {
  border-radius: 8px 8px 0 0;
  width: 100%;
  max-height: 183px;
  object-fit: cover;
}

.room-invite__org-img {
  position: absolute;
  right: 18px;
  bottom: -15px;
  max-width: 60px;
}

.room-invite__card-body {
  padding: 18px 20px 28px;
  border-radius: 0 0 8px 8px;
}

.room-invite__board-name {
  font-size: 20px;
  font-weight: 700;
  color: $color-white;
  margin-bottom: 18px;
  text-transform: none;
  word-break: break-all;
}

.room-invite__add-all {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.room-invite__count {
  font-size: 20px;
  color: $color-white;
}

.room-invite__add-btn {
  background: transparent;
  color: $color-primary;
  border: 2px solid $color-primary;
  font-size: 14px;
  border-radius: 20px;
  padding: 3px 10px;
}