@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.organization-detail {
    margin-top: 9px;
}

.organization-detail__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 6px;

    @include mq('sm', max) {
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .br__btn-theme-filled {
        width: 182px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            color: $color-white;
        }
    }
}

// table css
.organization-detail__table-wrapper {
    margin-top: 25px;
}

.organization-detail__table-wrapper.organization-detail__table-wrapper--org {
    .board-listing__cel {
        @include mq('sm', max) {
            word-break: break-all;
        }

        &:first-child {
            width: 275px;
        }
    }
}

.organization__board-listing-table {
    .board-listing__head {
        background: $color-dark-3;

        @include mq('sm', max) {
            display: none;
        }
    }

    .board-listing__row--head {
        border-bottom: 1px solid rgba($color-white, 0.5);
    }

    .board-listing__cel--head {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.43;
        color: $color-light-grey-1;
        text-transform: uppercase;
        border-top: none;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .board-listing__row {
        border-bottom: 1px solid rgba($color-white, 0.15);
    }

    .board-listing__cel {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $color-light-grey-1;
        border-top: none;
        padding-top: 11px;

        p {
            margin-bottom: 8px;
        }

        &:first-child {
            width: auto;
        }
    }

    .board-listing__cel--dropdown {
        text-align: right;
        padding-right: 47px;
    }

    .theme__dropdown {
        .theme__dropdownmenu {
            min-width: 172px;
        }
    }
}

// will be  refactored later
.organization-detail__table-wrapper--org {
    .board-listing__row {
        @include mq('sm', max) {
            display: flex;
            flex-wrap: wrap;
            border-bottom: none;
            border-top: 1px solid $table-row-border-mob;
            position: relative;
            padding-bottom: 10px;
        }
    }

    .board-listing__cel {
        @include mq('sm', max) {
            width: 50%;

            &::before {
                content: attr(data-label);
                display: block;
                text-transform: uppercase;
                color: $data-label-color;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.43;
            }
        }

        &--dropdown {
            @include mq('sm', max) {
                position: absolute;
                right: 0;
                top: 0;
                padding-right: 5px !important;
                width: 20%;

                &::before {
                    content: '';
                }
            }
        }
    }
}
