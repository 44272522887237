@import "../../../assets/sass/helpers/variables";
@import "../../../assets/sass/helpers/mixins";

.forgot-password {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;

  &__block{
    max-width: 330px;
    margin: 0 auto;
    @include mq("sm", max) {
      max-width: 314px;
    }
  }
  
  &__logo {
    max-width: 330px;
    margin-bottom: 50px;
    @include mq("sm", max) {
      max-width: 314px;
    }
  }

  &__text{
    font-size: 16px;
    color: white;
    margin-bottom: 30px;
  }
  
  &__btn-send {
    display: block;
    width: 100%;
    background: $color-primary;
    border-radius: 25px;
    box-shadow: $btn-box-shadow;
    margin: 44px 0 65px;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    color: $btn-color;
    height: 50px;
  }

  &__link{
    font-size: 16px;
    color: $color-primary;
    line-height: normal;
    text-decoration: underline;
    opacity: 0.9;
    transition: all 0.3s ease-in-out;
    display: block;
    text-align: right;
  }
}


.disclaimer {
  color: $color-white;
  font-size: 12px;
  opacity: 0.8;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  @include mq("sm", max) {
    display: none;
  }
}