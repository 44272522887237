@import "../../../assets/sass/helpers/variables";
@import "../../../assets/sass/helpers/mixins";

.thankyou {
  min-height: 100vh;
  position: relative;
  
  &::after{
    content: '';
    background: url(../../../assets/images/speaker.png) no-repeat;
    width: 516px;
    height: 100vh;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
    opacity: 0.61;
  }
  
  &__header{
    padding: 26px 0 13px;
    border-bottom: 1px solid $thankyou-header-border;
  }

  &__logo-link{
    max-width: 270px;
    display: block;
    text-align: end;
  }

  &__logo{
    max-width: 270px;
    margin-bottom: 4px;
  }

  &__tag-img{
    max-width: 146px;

  }

  &__body{
    margin-top: 151px;
    max-width: 594px;
  }

  &__heading{
    opacity: 0.9;
    font-size: 36px;
    line-height: 1.39;
    color: $thankyou-heading;
    margin-bottom: 30px;
    text-transform: none;
    font-weight: 400;
  }

  &__main-heading{
    color: white;
    opacity: 0.9;
    font-size: 80px;
    font-weight: 700;
    margin-bottom: 16px;
    text-transform: none;
  }

  &__sub-heading{
    color: white;
    opacity: 0.9;
    font-size: 30px;
    line-height: 1.33;
    margin-bottom: 30px;
    text-transform: none;
    font-weight: 400;
  }

  &__app-img{
    max-width: 102px;
  }
}