@import '../../../../assets/sass//helpers/variables';
@import '../../../../assets/sass/helpers/mixins';


.guest-room-detail {

  &__img-hero {
    width: 100%;
  }

  &__status {
    display: flex;
    align-items: center;
    margin: 14px 0 10px;
  }

  &__status-tag {
    margin-right: 10px;
  }

  &__time-wrap {
    display: flex;
    align-items: center;
  }

  &__time {
    color: white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 700;
    margin-left: 5px;
  }

  &__greenroom-time{
    font-size: 14px;
    color: $color-green;
    margin-bottom: 8px;
    line-height: normal;
  }

  &__room-info {
    display: flex;
    align-items: flex-start;
  }

  &__org-img {
    max-width: 69px;
    margin-right: 13px;
  }

  &__room-name {
    color: white;
    opacity: 0.9;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    text-transform: none;
    margin-bottom: 5px;
  }

  &__board-name {
    color: $guest-dashboard-board-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.09;
    text-transform: none;
  }

  &__link-wrapper {
    margin-top: 16px;
  }

  &__link-inner {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    flex-wrap: wrap;
  }

  &__link-input {
    margin-right: 15px;
    max-width: 298px;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    min-height: 24px;
    padding: 6px 8px;
    background: transparent;
    outline: none;
    border-radius: 4px;
    border: solid 1px rgba(white, 0.3);

    &::placeholder {
      opacity: 0.8;
    }

    &:focus,
    &.focus {
      outline: none;
      box-shadow: none;
      background: transparent;
      border: solid 1px rgba(white, 0.3);
      color: inherit;
    }

    @include mq('xl-14', max) {
      max-width: 270px;
    }
  }

  &__link-label {
    opacity: 0.5;
    font-size: 14px;
    font-weight: bold;
    color: white;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  &__link-tagline {
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    color: $data-label-color;
    margin-bottom: 16px;
  }

  &__room-desc {
    max-height: 267px;
    max-width: 439px;
    overflow-y: auto;
    font-size: 16px;
    line-height: 1.5;
    padding-right: 16px;
    color: $guest-room-desc-color;
    margin-top: 16px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 3px;
      border-radius: 1.5px;
      background-color: rgba($color-light-grey-3, 0.4);
    }
  }

  &__btn-outline {
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid $color-primary;
    background: transparent;
    border-radius: 20px;
    margin-bottom: 16px;
    width: 120px;
    height: 36px;
    margin-top: 16px;
  }

  &__btn-outline--decline {
    color: $color-decline;
    border: 2px solid $color-decline;
  }
  
  &__btn-outline--accept {
    color: green;
    border: 2px solid green;
  }

}

.height-30 {
  height: 30px;
}

.guest-room-detail__link-tagline {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: $data-label-color;
  margin-bottom: 16px;
}

.guest-room-detail__icon-btn {
  position: relative;

  .tool-msg {
      display: none;
  }

  &:hover {
      .tool-msg {
          display: block;
          font-size: 11px;
          font-weight: 500;
          border-radius: 3px;
          color: black;
          background: white;
          width: 118px;
          position: absolute;
          top: 0;
          left: calc(100% - 6px);
          z-index: 100;
          padding: 3px 10px;
      }
  }
}