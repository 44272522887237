.content-styling{
    color: #eb0c00;
}
.unauthorized_msg-alignment{
    text-align: center;
}
.unauthorized_btn-container{
    display: flex;
}
.unauthorized_btn{
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    width: 100px;
}