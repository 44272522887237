@import "../../../../assets/sass/helpers/variables";
@import "../../../../assets/sass/helpers/mixins";

.organization-detail__meta {
  display: flex;
  align-items: flex-start;
  @include mq("sm", max) {
    flex-wrap: wrap;
  }
}

.organization-detail__logo {
  max-width: 95px;
  min-width: 95px;
  margin-right: 20px;
  @include mq("sm", max) {
    margin-right: 10px;
  }
}

.organization-detail__org-name {
  color: $color-white;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  max-width: 166px;
  word-break: break-word;
  text-transform: inherit;
  @include mq("sm", max) {
    width: 100%;
  }
}

.organization-detail__producers {
  margin-left: 64px;
  @include mq("sm", max) {
    margin-left: 0;
    margin-top: 20px;
  }
}

.organization-detail__producer-title {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: normal;
  color: $color-grey-1;
  margin-bottom: 1px;
}

.producer__name {
  font-size: 16px;
  color: $color-white;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
}