@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.advance-search {
    max-width: 460px;
    margin: 0 auto;
    padding-top: 12px;
    margin-bottom: 84px;

    @include mq('sm', max) {
      margin-bottom: 40px;
    }

    &__icon-btn {
      min-width: 92px;
      height: 32px;
      margin-bottom: 52px;
  }

  &__main-heading{
    color: white;
    opacity: 0.9;
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 29px;
  }
  
  &__section-heading {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.43;
      color: $color-light-grey-1;
      padding: 8px 17px;
      text-transform: uppercase;
      background: $color-dark-3;
      margin-bottom: 24px;
      border-bottom: 1px solid rgba($color-white, 0.5);
  }
  
  &__footer {
      padding: 25px 0 0 0;
      border-top: 1px solid rgba($color-white, 0.24);
      margin-top: 38px;    
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
      .br__btn-theme-filled {
          min-width: 140px;
      }
  }
  
  &__btn-link {
      font-size: 16px;
      font-weight: 400;
      color: $color-primary;
      margin-right: 20px;
      background: none;
      border: none;
      padding: 0;
      line-height: 1;
  }
  
  &__form {
      margin-top: 24px;
  
      .form-group {
          margin-bottom: 24px;
      }
      .form-label {
          font-size: 14px;
          color: $color-white;
          text-transform: uppercase;
          font-weight: 700;
          line-height: normal;
          opacity: 0.5;
          margin-bottom: 6px;
          display: block;
      }
      .form-control {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          color: $color-white;
          min-height: 24px;
          padding: 6px 8px;
          background: transparent;
          outline: none;
          border-radius: 4px;
          border: solid 1px rgba($color-white, 0.3);
          &::placeholder {
              opacity: 0.8;
          }
          &:focus,
          &.focus {
              outline: none;
              box-shadow: none;
          }
      }
  
      .form__textarea {
          min-height: 168px;
      }
      .form-group--badge {
          position: relative;
          input {
              padding-right: 45px;
          }
      }
      .form__badge {
        display: none;
      }
  }

  &__date-time-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 14px;

    @include mq('sm', max) {
        flex-wrap: wrap;
    }
  }

  &__form-group--date{
      margin-right: 24px;
      input{
          min-width: 219px;
          text-transform: uppercase;
          position: relative;
      }
      input[type="date"]::-webkit-calendar-picker-indicator{
          position: absolute;
          right: 7px;
          top: 6px;
          background: url(../../../../assets/images/ic-calendar.svg) no-repeat;
          transform: rotate(180deg);
      }
  }
}




// checkbox css
.custom-checkbox-room {
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-bottom: 24px;
  
    /* Hide the browser's default checkbox */
    .custom-checkbox__input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  
      /* When the checkbox is checked, add a blue background */
      &:checked ~ .custom-checkbox__checkmark {
        background-color: $color-primary;
      
        /* Show the checkmark when checked */
        &:after {
          display: block;
        }
      }
    }
    /* Create a custom checkbox */
    .custom-checkbox__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: transparent;
      border: 2px solid $color-primary;
      border-radius: 50%;
  
      /* Style the checkmark/indicator */
      &::after {
        left: 7px;
        top: 4px;
        width: 6px;
        height: 12px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
  
        /* Create the checkmark/indicator (hidden when not checked) */
        content: "";
        position: absolute;
        display: none;
      }
    }
    
}
.custom-checkbox__label{
    margin-left: 32px;
    cursor: pointer;
    font-size: 16px;
    color: white;
}
  