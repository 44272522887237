@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.create-anonymous-user {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;

    &__block {
        max-width: 330px;
        margin: 0 auto;
        @include mq('sm', max) {
            max-width: 314px;
        }
    }

    &__logos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        padding: 22px 0 18px 0;
        &__logo {
            max-width: 259px;
            @include mq('sm', max) {
                max-width: 314px;
            }
        }
        &__beta-logo {
            max-width: 65px;
            margin-right: 10px;
        }
    }

    &__text {
        font-size: 16px;
        color: white;
        margin-bottom: 30px;
    }

    &__btn-send {
        color: $color-primary;
        border: 2px solid $color-primary;
        display: block;
        width: 100%;
        background: transparent;
        border-radius: 25px;
        box-shadow: $btn-box-shadow;
        margin: 50px 0 20px;
        font-size: 16px;
        font-weight: bold;
        height: 40px;
    }

    &__error {
        color: $color-red;
        font-size: 14px;
    }
}

.magic-disclaimer {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    @include mq('sm', max) {
        display: none;
    }

    &__text {
        color: $color-white;
        font-size: 12px;
        opacity: 0.8;
        margin-top: 47px;
    }
}
