@import "../../../assets/sass/helpers/variables";
.icon-button{
  background: transparent;
  border: 2px solid $color-primary;
  min-width: 116px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 700;
  color: $color-primary;
  padding: 0 11px;
}
.icon-button__img{
  max-width: 24px;
  margin-right: 9px;
}