@import "../../../../assets/sass/helpers/variables";
@import "../../../../assets/sass/helpers/mixins";
.organization__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mq("sm", max) {
    margin-top: 39px;
  }
  .organization__heading {
    font-size: 24px;
    font-weight: 700;
    line-height: normal;
    color: $color-white;
    opacity: 0.9;

    @include mq("sm", max) {
      text-transform: capitalize;
      margin-bottom: 25px;
    }
  }
}
