@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.guest-footer {
    border-top: 1px solid rgba(white, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 26px;

    @include mq('sm', max) {
        justify-content: center;
    }

    &__left {
        display: flex;

        @include mq('sm', max) {
            margin-bottom: 10px;
        }
    }

    &__logo {
        margin-right: 10px;
        max-width: 50px;
    }

    &__text {
        color: $thankyou-heading;
        opacity: 0.9;
        font-size: 16px;
        line-height: 1.38;
    }
}
