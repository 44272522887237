@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.guest-profile-body {
    max-width: 1040px;
    margin: 0 auto;
    padding-top: 32px;
}

.guest-room-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    padding: 40px 43px 38px 51px;
    margin-bottom: 10px;
    background: $guest-dashboard-card-bg;
    border-radius: 6px;

    &:nth-last-child(1) {
        border-bottom: none;
        margin-bottom: 0;
    }

    &__left {
        max-width: 439px;
        margin-right: 19px;
        min-width: 439px;

        @include mq('sm', max) {
            margin-right: 0;
            margin-bottom: 15px;
            border-bottom: 1px solid $color-dark-1;
            padding-bottom: 15px;
        }
    }

    &__img-hero {
        max-width: 439px;

        @include mq('sm', max) {
            max-width: 100%;
            height: auto;
        }
    }

    &__status {
        display: flex;
        align-items: center;
        margin: 14px 0 8px;
    }

    &__status-tag {
        margin-right: 10px;
    }

    &__time-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &__time {
        color: white;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 700;
        margin-left: 5px;
    }

    &__greenroom-time {
        font-size: 14px;
        color: $color-green;
        margin-bottom: 8px;
        line-height: normal;
    }

    &__room-info {
        display: flex;
        align-items: flex-start;
    }

    &__org-img {
        max-width: 69px;
        margin-right: 13px;
    }

    &__room-name {
        color: white;
        opacity: 0.9;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.17;
        text-transform: none;
        margin-bottom: 5px;
        cursor: pointer;
        transition: $transition-3ms;

        &:hover {
            color: $color-primary;
        }
    }

    &__board-name {
        color: $guest-dashboard-board-color;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.09;
        text-transform: none;
        word-break: break-all;
    }

    //midle column

    &__speaker-list {
        max-width: 326px;
        min-width: 310px;
        overflow-y: auto;
        height: 260px;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            width: 3px;
            border-radius: 1.5px;
            background-color: rgba($color-light-grey-3, 0.4);
        }

        @include mq('sm', max) {
            max-width: 100%;
        }
    }

    &__speaker-item {
        margin-bottom: 16px;
    }

    &__speaker-img {
        max-width: 65px;
        margin-right: 11px;
    }

    &__speaker-name-wrap {
        display: flex;
        align-items: flex-start;
    }

    &__speaker-name {
        color: white;
        opacity: 0.9;
        font-size: 18px;
        line-height: 1.2;
        text-transform: none;
        margin-bottom: 5px;
        word-break: break-all;
        overflow: hidden;
        max-width: 15ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }

    &__speaker-role {
        margin-left: 11px;
        background: black;
        padding: 3px 6px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: normal;
        color: $guest-dashboard-role-bg;
    }

    &__speaker-blurb {
        color: white;
        opacity: 0.4;
        font-size: 14px;
        line-height: 1.29;
        overflow: hidden;
        max-width: 15ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }

    &__attendee-wrap {
        margin-top: 20px;
    }

    &__attendee-heading {
        color: white;
        opacity: 0.4;
        font-size: 16px;
        font-weight: bold;
        text-transform: none;
    }

    &__attendee-list {
        display: flex;
        align-items: center;
        margin-top: 18px;
        flex-wrap: wrap;
    }

    &__attendee-item {
        margin-right: -10px;

        @include mq('sm', max) {
            margin-bottom: 14px;
        }

        &--count {
            margin-right: 0;
            width: 44px;
            border-radius: 50%;
            height: 44px;
            background: black;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__attendee-img {
        max-width: 44px;
        border-radius: 50%;
        max-height: 44px;
        border: 2px solid $guest-dashboard-card-bg;
        object-fit: cover;
        height: 44px;
    }

    &__attendee-count-text {
        color: white;
        opacity: 0.5;
        font-size: 16px;
        font-weight: bold;
    }

    // right column
    &__right {
        position: absolute;
        bottom: 38px;
        right: 44px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @include mq('sm', max) {
            position: static;
            width: 100%;
            justify-content: space-between;
            margin-top: 20px;
        }
    }

    &__btn-outline {
        color: $color-primary;
        font-size: 16px;
        font-weight: bold;
        border: 2px solid $color-primary;
        background: transparent;
        border-radius: 20px;
        padding: 0px 11px;
        margin-bottom: 16px;
        width: 140px;
        min-width: 140px;
        height: 35px;
    }

    &__pastroom-btn-outline {
        color: $color-primary;
        font-size: 16px;
        font-weight: bold;
        border: 2px solid $color-primary;
        background: transparent;
        border-radius: 20px;
        padding: 0px 11px;
        margin-bottom: 16px;
        width: 180px;
        height: 35px;
    }

    &__btn-filled {
        width: 169px;
        max-height: 50px;
        min-height: 50px;
        border-radius: 120px !important;
    }
}

.guest-room-row + .guest-old-rooms {
    margin-top: 39px;
}

//old rooms i.e declined and past
// to be refactored later

.guest-old-rooms {
    &__heading {
        font-size: 14px;
        font-weight: 900;
        color: $guest-old-rooms-heading;
        margin-bottom: 3px;
        padding: 8px 24px 5px;
        background: $guest-old-rooms-heading-bg;
        text-transform: uppercase;
    }

    &__list {
        margin-bottom: 39px;
    }

    &__item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 15px 12px;
        margin-bottom: 4px;
        background: $guest-old-rooms-item-bg;

        @include mq('sm', max) {
            flex-wrap: wrap;
        }
    }

    &__room-type-icon {
        margin-bottom: 14px;
    }

    &__time {
        color: white;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 700;
    }

    &__recording-img {
        margin-left: 10px;
        margin-top: -3px;
    }

    &__room-info {
        display: flex;
        align-items: flex-start;

        @include mq('sm', max) {
            margin-bottom: 15px;
        }
    }

    &__org-img {
        max-width: 69px;
        margin-right: 13px;
    }

    &__room-name {
        color: white;
        opacity: 0.9;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.17;
        text-transform: none;
        margin-bottom: 5px;
        max-width: 325px;
        cursor: pointer;
        transition: $transition-3ms;

        &:hover {
            color: $color-primary;
        }
    }

    &__board-name {
        color: $guest-dashboard-board-color;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.09;
        text-transform: none;
    }
}

.room-start-timer {
    background: $color-decline;
    border-radius: 4px;
    padding: 1px 5px;
    color: white;
    opacity: 0.9;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.room-start-timer-img {
    margin-right: 6px;
    max-width: 18px;
}

.room-start-timer-text {
    margin-top: 3px;
}
