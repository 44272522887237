@import "../../../../assets/sass/helpers/variables";

.board_content_wrapper{
  padding-top: 78px;
}
.bcw_action_bar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .bcw_action_list{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .bcw_action_link{
      font-size: 16px;
      line-height: 22px;
      color: $color-light-grey-3;
      font-weight: 500;
      margin-right: 46px;
      opacity: .7;
      &.active{
        color: $color-white;
        position: relative;
        opacity: 1;
        &::before{
          content: '';
          position: absolute;
          bottom: -6px;
          left: 0;
          height: 3px;
          width: 100%;
          background: $color-white;
        }
      }
    }
  }
  .bcw_create_board_btn{
    color: $color-nero;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 11px 0;
    background: $color-light-grey-4;
    border-radius: 4px;
    border: 1px solid $color-grey-4;
    width: 182px;
  }
}