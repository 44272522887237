@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';
.full-screen-modal {
    .modal-dialog {
        max-width: 1100px;
        @include mq('sm', max) {
            max-width: 396px;
        }
    }
    .modal-content {
        background: black;
        box-shadow: 0 1px 5px 0 rgba(black, 0.15);
        padding: 0;
        @include mq('sm', max) {
            padding: 40px 40px 40px 40px;
        }
    }
    .full-screen-modal__body {
        padding: 0;
    }

    &__minize-icon {
        max-width: 30px;
        position: absolute;
        top: 27px;
        right: 27px;
        z-index: 11;
        cursor: pointer;
    }
    .active-video-container {
        width: 280px;
        height: 175px;
        background-color: black;
        right: 0;
        bottom: 65px;
    }

    .active-video-display {
        width: 280px;
        height: 175px;
        object-fit: contain;
    }
}

// for blur backgrouond when modal is open
.modal-open {
    .layout {
        background-color: rgba($card-body-color, 0.769);
        filter: blur(3px);
    }
}

.screenshare-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}
