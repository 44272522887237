@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.organization-detail-wrpper {
    margin-top: 9px;
}

// table css
.organization-detail__table-wrapper {
    margin-top: 25px;
}

.organization__board-listing-table {
    .board-listing__head {
        background: $color-dark-3;
    }

    .board-listing__row--head {
        border-bottom: 1px solid rgba($color-white, 0.5);
    }

    .board-listing__cel--head {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.43;
        color: $color-light-grey-1;
        text-transform: uppercase;
        border-top: none;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .board-listing__row {
        border-bottom: 1px solid rgba($color-white, 0.15);
    }

    .board-listing__cel {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $color-light-grey-1;
        border-top: none;
        padding-top: 11px;
        cursor: pointer;

        p {
            margin-bottom: 8px;
        }
    }

    .board-listing__cel--dropdown {
        text-align: right;
        padding-right: 47px;
    }

    .theme__dropdown {
        .theme__dropdownmenu {
            min-width: 172px;
        }
    }
}

.expired-status {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    opacity: 0.9;
    color: $color-white;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid rgba($color-white, 0.2);
    padding: 2px 4px;
}

.board-detail__table-wrapper {
    .board-listing__cel--head {
        &:nth-child(1) {
            width: 27%;
        }

        &:nth-child(4) {
            width: 17%;
            word-break: break-word;
        }
    }

    .board-listing__cel {
        &:nth-child(1) {
            width: 27%;
            word-break: break-word;
        }

        &:nth-child(4) {
            width: 17%;
            word-break: break-word;
        }
    }
}

.board-detail__table-wrapper {
    .board-listing__cel--head {
        &:nth-child(1) {
            width: 217px;
        }

        &:nth-child(2) {
            width: 180px;
        }

        &:nth-child(3) {
            width: 258px;
        }

        &:nth-child(4) {
            width: 266px;
            word-break: break-word;
        }
    }

    .board-listing__cel {
        &:nth-child(1) {
            width: 217px;
            word-break: break-word;
        }

        &:nth-child(2) {
            width: 180px;
        }

        &:nth-child(3) {
            width: 258px;
            word-break: break-word;
        }

        &:nth-child(4) {
            width: 266px;
            word-break: break-word;
        }

        &--blurb {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.29;
            color: $color-grey-3;
            vertical-align: middle;
        }

        &--role {
            font-size: 12px;
            font-weight: 400;
            line-height: 1.67;
            color: $color-light-grey-1;
            vertical-align: middle;
        }

        &--email {
            vertical-align: middle;
        }

        @include mq('sm', max) {
            word-break: break-all;
        }
    }
}

// will be  refactored later
.board-detail__table-wrapper {
    .board-listing__row {
        @include mq('sm', max) {
            display: flex;
            flex-wrap: wrap;
            border-bottom: none;
            border-top: 1px solid $table-row-border-mob;
            position: relative;
            padding-bottom: 10px;
        }
    }

    .board-listing__cel {
        @include mq('sm', max) {
            width: 50% !important;

            &::before {
                content: attr(data-label);
                display: block;
                text-transform: uppercase;
                color: $data-label-color;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.43;
            }
        }

        &--room-name {
            @include mq('sm', max) {
                width: 80% !important;
            }
        }

        &--time {
            @include mq('sm', max) {
                width: 100% !important;
            }
        }

        &--dropdown {
            @include mq('sm', max) {
                position: absolute;
                right: 0;
                top: 0;
                padding-right: 5px !important;
                width: 20%;

                &::before {
                    content: '';
                }
            }
        }
    }
}
