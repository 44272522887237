@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.room-invite {
    margin-top: 9px;
    @include mq('sm', max) {
        padding-bottom: 104px;
    }
}

.room-invite__empty-wrapper {
    max-width: 177px;
    margin: 115px auto 0;
    text-align: center;
}

.room-invite__empty-img {
    margin-bottom: 2px;
}

.room-invite__empty-text {
    font-size: 16px;
    color: white;
    opacity: 0.6;
    line-height: 1.5;
    text-align: center;
}

.room-invite__search {
    @include mq('sm', max) {
        margin: 0 -20px;
        padding: 8px 20px;
        background: $card-body-color;
    }

    .form-group {
        @include mq('sm', max) {
            margin-bottom: 0;
            position: relative;
        }

        &::before {
            @include mq('sm', max) {
                content: '';
                background: url(../../../../assets/images/ic-search.png);
                display: block;
                width: 24px;
                height: 24px;
                position: absolute;
                top: 6px;
                left: 6px;
            }
        }
    }
}

.room-invite__search-label {
    font-size: 14px;
    color: $color-white;
    text-transform: uppercase;
    font-weight: 700;
    line-height: normal;
    opacity: 0.5;
    margin-bottom: 6px;

    @include mq('sm', max) {
        display: none;
    }
}

.room-invite__search-input {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $color-white;
    min-height: 24px;
    padding: 6px 8px;
    background: transparent;
    outline: none;
    border-radius: 4px;
    border: solid 1px rgba($color-white, 0.3);
    max-width: 410px;

    &::placeholder {
        opacity: 0.8;
    }

    &:focus,
    &.focus {
        outline: none;
        box-shadow: none;
        color: $color-white;
        background-color: transparent;
        border-color: $color-primary;
        caret-color: $color-primary;

        @include mq('sm', max) {
            background: $room-invite-search;
        }
    }

    @include mq('sm', max) {
        background: $room-invite-search;
        border: none;
        border-radius: 8px;
        padding-left: 35px;
    }
}

.room-invite__btn-add-guest-wrapper {
    padding-top: 10px;
    padding-bottom: 14px;
}

.room-invite__btn-add-guest {
    color: $color-primary;
    font-size: 16px;
    font-weight: 700;
    border: 1px solid $color-primary;
    border-radius: 30px;
    padding: 7px 15px 7px 13px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.room-invite__btn-add-guest-icon {
    margin-right: 10px;
}

.room-invite__member-name {
    display: flex;
    align-items: center;
    @include mq('sm', max) {
        font-size: 16px;
        font-weight: 700;
        opacity: 0.9;
        color: white;
        line-height: 1;
        margin-bottom: 2px;
    }
}

.room-invite__member-name-img {
    max-width: 40px;
    margin-right: 7px;
    @include mq('sm', max) {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 50px;
    }
}
