@import '../../../../assets//sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

//midle column
.guest-room-detail {
    &__users-heading {
        color: white;
        opacity: 0.8;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 15px;
        text-transform: capitalize;
    }

    &__users-list {
        max-height: 355px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            width: 3px;
            border-radius: 1.5px;
            background-color: rgba($color-light-grey-3, 0.4);
        }
    }

    &__users-list--speaker {
        margin-bottom: 40px;
    }

    &__users-item {
        max-width: 320px;
        margin-bottom: 30px;
        cursor: pointer;
    }

    &__users-img {
        width: 65px;
        margin-right: 11px;
        height: 65px;
        object-fit: contain;
    }

    &__users-img--attendee {
        width: 50px;
        height: 50px;
    }

    &__users-name {
        color: white;
        opacity: 0.9;
        font-size: 18px;
        line-height: 1.2;
        text-transform: none;
        margin-bottom: 3px;
        font-weight: 400;
        overflow: hidden;
        max-width: 15ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }

    &__users-name--attendee {
        font-size: 16px;
    }

    &__users-blurb {
        color: white;
        opacity: 0.4;
        font-size: 14px;
        line-height: 1.29;
        font-weight: 400;
        overflow: hidden;
        max-width: 15ch;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: default;
    }

    &__attendee-wrap {
        margin-top: 31px;
    }

    &__attendee-heading {
        color: white;
        opacity: 0.4;
        font-size: 16px;
        font-weight: bold;
        text-transform: none;
    }

    &__attendee-list {
        display: flex;
        align-items: center;
        margin-top: 13px;
    }

    &__attendee-item {
        margin-right: 14px;

        &--count {
            margin-right: 0;
        }
    }

    &__attendee-img {
        max-width: 40px;
    }

    &__attendee-count-text {
        color: white;
        opacity: 0.5;
        font-size: 16px;
        font-weight: bold;
    }
}
