@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.organization__board-listing-table {
    .board-listing__head {
        background: $color-dark-3;
    }

    .board-listing__row--head {
        border-bottom: 1px solid rgba(white, 0.5);
    }

    .board-listing__row {
        border-bottom: 1px solid rgba(white, 0.15);
    }

    .board-listing__cel--dropdown {
        text-align: right;
        padding-right: 47px;
    }

    .board-listing__cel--head {
        &.padding-left-52 {
            padding-left: 52px;
        }

        &--width-248 {
            width: 248px;
        }

        &--width-210 {
            width: 210px;
        }
    }

    .theme__dropdown {
        .theme__dropdownmenu {
            min-width: 172px;
        }
    }

    .board-listing__cel:first-child {
        width: auto;
    }
}

.room-detail__attende-name {
    opacity: 0.9;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: $color-white;
    word-break: break-all;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
}

.room-detail__attende-blurb {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
    color: $color-grey-3;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;

    @include mq('sm', max) {
        margin-top: 2px;
    }
}

.room-detail__attende-email {
    opacity: 0.9;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: $color-white;
}

.room-detail__attende-img {
    max-width: 32px;

    @include mq('sm', max) {
        max-width: 50px;
        margin-right: 12px;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.room-detail__attende-role {
    opacity: 0.8;
    font-family: Lato;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;

    @include mq('sm', max) {
        display: none;
    }
}

.room-detail__table {
    .theme__dropdown {
        .theme__dropdownmenu {
            min-width: 192px;
        }
    }
}

.room-detail__table {
    @include mq('sm', max) {
        display: block;
    }
}

.room-detail__rsvp-icon {
    display: none;

    @include mq('sm', max) {
        display: block;
        margin-left: 4px;
    }
}

.room-detail__media-body {
    @include mq('sm', max) {
        display: flex;
    }
}

.room-detail__table-body {
    @include mq('sm', max) {
        display: block;
    }

    .room-detail__table-row {
        border-bottom: 1px solid rgba(white, 0.15);

        @include mq('sm', max) {
            display: block;
            position: relative;
            border-bottom: none;
            padding-left: 62px;
            padding-right: 35px;
            padding-bottom: 24px;
            min-height: 54px;
        }
    }

    .room-detail__table-cel {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $color-light-grey-1;
        border-top: none;
        padding-top: 11px;
        cursor: pointer;

        @include mq('sm', max) {
            display: block;
            padding: 0;
        }

        &--icon {
            @include mq('sm', max) {
                display: none;
            }
        }

        &--email {
            @include mq('sm', max) {
                display: none;
            }
        }

        &--dropdown {
            @include mq('sm', max) {
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                padding: 0;
            }
        }
    }
}
