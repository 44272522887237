@import '../../../assets/sass/helpers/variables';

.guest-login {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;

    &__register {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: white;
    }

    &__register-link {
        color: $color-primary;
        text-decoration: underline;
        opacity: 0.9;

        &:hover {
            color: $color-primary;
            text-decoration: underline;
        }
    }

    &__disclaimer {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__disclaimer-text {
        color: $color-white;
        font-size: 12px;
        opacity: 0.8;
        margin-top: 27px;
    }
}
.guest-login__block {
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 80px;
    @media screen and (max-width: 525px) {
        max-width: 314px;
    }
}
.guest-login-logo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    padding: 22px 0 18px 0;
    &__login__logo {
        max-width: 273px;
        @media screen and (max-width: 525px) {
            max-width: 314px;
        }
    }
    &__beta__logo {
        max-width: 65px;
        margin-right: 10px;
    }
}

.loginform__link {
    font-size: 16px;
    color: $color-primary;
    line-height: normal;
    text-decoration: underline;
    opacity: 0.9;
    transition: $transition-3ms;
    &:hover {
        text-decoration: underline;
        color: $color-primary;
    }
}
.btn_signin {
    display: block;
    width: 100%;
    background: $color-primary;
    border-radius: 25px;
    box-shadow: $btn-box-shadow;
    margin: 40px 0px;
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
    color: $btn-color;
    height: 40px;
}
.disclaimer {
    color: $color-white;
    font-size: 12px;
    opacity: 0.8;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    @media screen and (max-width: 525px) {
        display: none;
    }
}
.loginform__error {
    color: $color-red;
    font-size: 16px;
    line-height: normal;
    font-weight: normal;
    margin-top: 3px;
}
