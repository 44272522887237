@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.room-invite__header-top {
  margin: 13px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @include mq("sm", max){
    margin: 30px 0 18px;
  }
}

.room-invite__heading {
  font-size: 24px;
  font-weight: 700;
  opacity: 0.9;
  color: $color-white;
  text-transform: none;
}

.room-invite__header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.room-invite__header-bottom-left {
  display: flex;
  align-items: center;
}

.mr-15 {
  margin-right: 15px;
}

.room-invite__selected {
  font-size: 16px;
  color: $color-white;
  font-weight: 400;
}

.room-invite__clear-all {
  color: $color-primary;
  cursor: pointer;
  font-weight: 500;
}

.room-invite__btn-wrap{
  @include mq("sm", max){
    position: fixed;
    bottom: 0;
    right: 0;
    background: black;
    left: 0;
    text-align: right;
    z-index: 2;
    padding: 27px 0 38px;
    line-height: 40px;
    padding-right: 20px;
  }
}

.room-invite__header-bottom-right{
  @include mq("sm", max){
    position: fixed;
    bottom: 0;
    left: 0;
    background: black;
    z-index: 2;
    right: 0;
    line-height: 40px;
    padding: 27px 0 38px;
    max-width: 160px;
    padding-left: 20px;
  }
}