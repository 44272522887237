@import '../../../assets/sass/helpers/variables';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px black inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.guest-profile-edit {
  max-width: 460px;
  margin: 60px auto 30px;

  @media screen and (max-width: 525px) {
    max-width: 350px;
    margin: 35px 0 45px;
  }

  &__logo {
    max-width: 450px;
    margin-bottom: 40px;
    display: block;

    @media screen and (max-width: 525px) {
      max-width: 350px;
      margin-bottom: 29px;
    }
  }

  &__main-heading{
    font-size: 22px;
    color: white;
    opacity: 0.9;
    font-weight: 700;
    text-transform: uppercase;
    margin: 52px 0 30px;
  }

  //new css
  &__form-group{
    margin-bottom: 24px;
  }

  &__form-label{
    font-size: 14px;
    font-weight: 700;
    color: $guest-profile-label;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  &__phone-images{
    display: flex;
    margin: 16px 0 22px;
  }

  &__phone-img{
    margin-right: 9px;
  }

  &__footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 24px 0 0 0;
    border-top: 1px solid rgba($color-white, 0.24);
  }

  &__input-dropdown-wrap{
    position: relative;

    .br__form-control{
      padding-right: 70px;
    }
  }
}

.guest-profile-edit__block {

  @media screen and (max-width: 525px) {
    padding: 0 20px;
  }

  .form-group--file {
    margin-bottom: 43px;
    @media screen and (max-width: 525px) {
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .form__uploadlogo {
    width: 136px;
    height: 136px;
    justify-content: center;
    margin-top: 37px;

    .linkcolor {
      margin-bottom: 0;
    }
  }

  .br__form-label {
    @media screen and (max-width: 525px) {
      display: none;
    }
  }

  .br__form-control {
    @media screen and (max-width: 525px) {
      border: none;
      border-bottom: solid 1px rgba(255, 255, 255, 0.3);
    }
  }

  textarea {
    @media screen and (max-width: 525px) {
      height: 100%;
    }
  }
}


.guest-profile-edit__btn {
  width: 100%;
  max-width: 95px;
  border-radius: 25px;
  font-size: 18px;
  height: 45px;

  @media screen and (max-width: 525px) {
    max-width: 314px;
    margin: 26px auto 0;
    display: block;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: black;
  opacity: 1;
}

.guest-mb-0 {
  margin-bottom: 0;
}

.optional-tag {
  color: $room-invite-role-color;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 12px;
}

.guest-head-tagline{
  font-size: 14px;
  font-weight: 600;
  color: $data-label-color;
  margin: 7px 0 16px;
}

.guest-toggle {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  &__text{
    font-size: 14px;
    margin-right: 9px;
    color: $color-light-grey-1;
  }
}

.toggle-checkbox {
  opacity: 0;
  position: absolute;

  &:checked+.toggle-label {
    background: $color-primary;
  }

  &:checked+.toggle-label .toggle-ball {
    transform: translateX(18px);
    background-color: #000;
  }
}

.toggle-label {
  background: #000;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  height: 19px;
  width: 38px;
  border: 3px solid $color-primary;
  transition: transform 0.2s linear;
}

.toggle-ball {
  background-color: $color-primary;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  height: 10px;
  width: 10px;
  transition: transform 0.2s linear;
}

// dropdown and custom radio button css

// dropdown css
.guest-profile-edit__dropdown{
  position: absolute;
  top: 0;
  right: 0;

  &--textarea{
    bottom: 0;
    top: unset;
  }
}
.guest-profile-edit__dropdown-toggle{
  padding: 6px 11px;
  border: 1px solid rgba(white, 0.3);
  border-radius: 4px;
  color: $room-invite-role-color;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  height: 38px;
  width: 65px;

  &::after{
    transition: all 0.3s ease-in-out;
  }
  
  &[aria-expanded="true"]::after{
    transform: rotate(180deg);
    border-top-color: white;
  }
  
}
.guest-profile-edit__dropdown-menu{
  width: 100%;
  background: $card-body-color;
  border: 1px solid rgba(white, 0.15);
  border-radius: 4px;
  min-width: 230px;
}
.guest-profile-edit__dropdown-item{
  padding: 0;
  &:hover{
    color: inherit;
    text-decoration: none;
    background-color: transparent;
  }
}

// radio button css

/* The container */
.custom-radio {
  position: relative;
  cursor: pointer;
  user-select: none;
  color: white;
  font-size: 14px;
  display: block;
  padding: 12px 10px;

  &:hover{
    background: rgba(white, 0.06);
  }
  
  .custom-radio__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    
    &:checked ~ .custom-radio__checkmark {
      background-color: transparent;
      
      &::after {
        display: block;
      }
    }
  }
  
  .custom-radio__checkmark {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid $color-primary;
    border-radius: 50%;
    
    &::after {
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $color-primary;
      content: "";
      position: absolute;
      display: none;
    }
  }
  .custom-radio__text{
    margin-left: 45px;
    display: flex;
    align-items: center;
  }
  .guest-profile-edit__visibility-icon{
    margin-right: 10px;
  }
}

.truncate-input{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}