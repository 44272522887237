@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 22px 0 18px 0;
    border-bottom: 1px solid rgba($color-white, 0.1);

    @include mq('sm', max) {
        padding: 41px 20px 18px;
    }
}

.header__logo {
    max-width: 262px;
    cursor: pointer;

    @include mq('sm', max) {
        max-width: 200px;
    }
}

.header__menu {
    color: $color-white;

    &.show {
        .header__arrow {
            transform: rotate(180deg);
        }
    }

    .header__dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .header__media {
        cursor: pointer;
        align-items: center;
    }

    .header__user-img {
        margin-right: 10px;
        border-radius: 4px;

        @include mq('sm', max) {
            margin-right: 0;
        }
    }

    .header__user-name {
        color: $color-white;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15ch;

        @include mq('sm', max) {
            display: none;
        }
    }

    .header__arrow {
        margin-right: 0;
        margin-left: 40px;
        transition: $transition-3ms;

        @include mq('sm', max) {
            display: none;
        }
    }

    .header__dropdown-menu {
        top: 23px !important;
        min-width: 250px;
        border-radius: 4px;
        background: $card-body-color;
        border: 1px solid rgba($color-white, 0.15);
        padding: 15px 20px;

        @include mq('sm', max) {
            min-width: 120px;
        }
    }

    .header__dropdown-item {
        color: $color-white;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        display: block;
        text-align: right;

        &:hover {
            color: $color-white;
        }
    }
}
