@import "../../../assets/sass/helpers/variables";
@import "../../../assets/sass/helpers/mixins";

.invite-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
  &__block {
    max-width: 330px;
    margin: 0 auto;
    @include mq("sm", max) {
      max-width: 314px;
    }
  }
  &__logo {
    max-width: 330px;
    margin-bottom: 88px;
    @include mq("sm", max) {
      max-width: 314px;
    }
  }
  &__app-img{
    max-width: 130px;
  }
}