@import '../../../assets/sass/helpers/variables';
.form__uploadlogo {
    cursor: pointer;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    margin-top: 6px;
    border: 2px dashed rgba($color-primary, 0.76);
    .form_selected_image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .logo__ratio {
        font-size: 50px;
        font-weight: 700;
        color: $color-nero;
        opacity: 0.9;
        line-height: 1.9;
    }
    .logo__drag {
        font-size: 16px;
        line-height: 1.19;
        color: $color-white;
        opacity: 0.5;
        text-align: center;
        font-weight: 700;
        text-transform: capitalize;
        span {
            display: block;
            font-weight: 400;
        }
        .linkcolor {
            color: $color-primary;
        }
    }
}

.logo-browse {
    margin-bottom: 24px;
}

.logo-browse-wrapper {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    .logo-browse {
        padding-right: 15px;
        margin-right: 15px;
        position: relative;
        margin-bottom: 0;
        &::after {
            content: '';
            background: $color-grey-4;
            height: 27px;
            width: 1px;
            display: inline-block;
            position: absolute;
            right: 0;
        }
    }
}

.form-group--file {
    position: relative;
    label.form-group--file-label {
        opacity: 1;
    }
    .form-group--file-span {
        opacity: 0.5;
        text-transform: capitalize;
    }
}

//hiding input type file
.form-group--file {
    input {
        position: absolute;
        width: 0;
        clip: rect(0,0,0,0);
    }
}

.form-group--file__camera-img {
    position: absolute;
    right: -17px;
    bottom: -15px;
    background: black;
    border-radius: 50%;
}
