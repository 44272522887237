@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';
.board-detail__subtitle {
    font-size: 12px;
    color: $color-grey-1;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 1px;
    text-transform: uppercase;
}
.board-detail__names-list {
    font-size: 16px;
    color: $color-white;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.board-detail__board-description {
    max-width: 324px;
    font-size: 16px;
    color: $color-white;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 2px;
}
.board-detail__readmore {
    color: $color-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline;
    margin-top: 2px;
    text-transform: capitalize;
    display: inline-block;
    cursor: pointer;
    @include mq("sm", max){
        color: white;
    }
}
.board-detail__truncate {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
