@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.magic-link-sender {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;

    &__block {
        max-width: 330px;
        margin: 0 auto;
        margin-bottom: 100px;
        @include mq('sm', max) {
            max-width: 314px;
        }
    }

    &__logos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
        padding: 22px 0 18px 0;
        &__logo {
            max-width: 259px;
            cursor: pointer;
            @include mq('sm', max) {
                max-width: 200px;
            }
        }
        &__beta-logo {
            max-width: 65px;
            margin-right: 10px;
        }
    }

    &__text {
        font-size: 16px;
        color: white;
        margin-bottom: 30px;
    }

    &__btn-send {
        display: block;
        width: 100%;
        background: $color-primary;
        border-radius: 25px;
        box-shadow: $btn-box-shadow;
        margin: 44px 0 50px;
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        color: white;
        height: 40px;
    }

    &__error {
        color: $color-red;
        font-size: 14px;
    }

    &__register {
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: white;
    }

    &__register-link {
        color: $color-primary;
        text-decoration: underline;
        opacity: 0.9;

        &:hover {
            color: $color-primary;
            text-decoration: underline;
        }
    }

    &__disclaimer {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
    }

    &__disclaimer-text {
        color: $color-white;
        font-size: 12px;
        opacity: 0.8;
        margin-top: 47px;
    }
}
