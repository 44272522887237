@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.board-detail__header {
  align-items: flex-start;
  margin-top: 9px;
}

.board-detail__main-info {
  display: flex;
  align-items: flex-start;

  @include mq("sm", max) {
    flex-wrap: wrap;
    padding: 0;
  }
}

.board-detail__images {
  position: relative;
  max-width: 210px;
  min-width: 210px;
  margin-right: 30px;
  @include mq("sm", max) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
}
.board-detail__cover-img{
  @include mq("sm", max) {
    width: 100%;
    max-height: 208px;
    object-fit: cover;
  }
}
.board-detail__org-img {
  max-width: 48px;
  position: absolute;
  bottom: -14px;
  right: 11px;
  &--mob{
    display: none;
  }
  @include mq("sm", max){
    position: static;
    max-width: 95px;
    min-width: 95px;
    margin-right: 10px;
    &--web{
      display: none;
    }
    &--mob{
      display: block;
    }
  }
}
.board-detail__title-wrap{
  @include mq("sm", max){
    margin: 20px 0;
    padding: 0 15px;
    display: flex;
    align-items: flex-start;
  }
}
.board-detail__title {
  font-size: 24px;
  color: $color-white;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
  word-break: break-word;
  text-transform: inherit;
  @include mq("sm", max){
    display: block;
  }
}

.board-detail__board-name {
  font-size: 16px;
  color: $color-light-grey-1;
  font-weight: 700;
  line-height: normal;
  word-break: break-word;
  @include mq("sm", max){
    color: $color-primary;
  }
}

.board-detail__btn-wrapper {
  text-align: right;
  margin-top: 24px;
  @include mq("sm", max){
    text-align: left;
  }
}
@include mq("sm", max){
  .mt-30{
    margin-top: 30px;
  }
}