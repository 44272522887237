@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.guest-sidenav {
    background: $guest-dashboard-card-bg;
    width: 100px;
    position: absolute;
    top: 108px;
    z-index: 0;
    left: 0;
    bottom: 155px;
    border-radius: 6px;
    z-index: 2;

    &__inner-wrap {
        margin-top: 90px;
        background: $guest-dashboard-card-bg;
        position: fixed;
        top: 0;
        width: 100px;
        bottom: 155px;
        border-radius: 6px;
    }
    &__list {
        margin-top: 31px;
    }

    &__item {
        margin-bottom: 52px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    &__item-link {
        display: block;
        text-align: center;
        &:hover {
            .guest-sidenav__item-name {
                color: $color-primary;
            }

            .guest-sidenav__item-icon {
                &::before {
                    color: $color-primary;
                }
            }
        }
    }

    &__item-name {
        color: white;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        opacity: 0.8;
        display: block;
        transition: $transition-3ms;
    }

    &__item-icon {
        font-size: 26px;
        margin-bottom: 8px;

        &::before {
            transition: $transition-3ms;
        }
    }
}
.guest-sidenav + .layout__content {
    width: calc(100% - 75px);
    margin-left: auto;
}
