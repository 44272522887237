//===========================================
// -Variables start
//===========================================

// Colors start

// define your vars or override bootstrap vars
$color-dark-1: #202124;
$color-dark-2: #36373a;
$color-dark-3: #323232;
$color-white: #fff;
$color-primary: #1a74e2;
$color-nero: #212121;
$btn-color: #e4e4e4;
$input-line-color: #616161;
$btn-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);

// transitions
$transition-3ms: all 0.3s ease-in-out;

// Typography Family
$font-default: 'Lato', sans-serif;

// define your Break Points or override bootstrap Break Points
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xl-14: 1400px
);
$card-body-color: #191919;
$color-red: #ff4238;
$color-grey-1: #757675;
$color-grey-3: #7a7a7a;
$color-grey-4: #979797;
$color-light-grey-1: #eceeef;
$color-light-grey-3: #f6f6f7;
$color-light-grey-4: #e0e0e0;

$room-invite-role-color: #7f7f7f;
$table-head-bg: #323232;
$input-disabled-web: #2d2d2d;
$table-row-border-mob: #979797;
$data-label-color: #6c6c6c;
$room-invite-search: #2d2d2d;

$thankyou-header-border: #202020;
$thankyou-heading: #cbcbcb;

$lounge-color: #bf7aff;

$guest-dropdown-bg: $color-dark-1;
$guest-dropdown-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.47), 0 1px 10px 0 rgba(0, 0, 0, 0.49), 0 2px 4px -1px rgba(0, 0, 0, 0.49);

$guest-dashboard-board-color: $data-label-color;
$guest-old-rooms-heading: #b0b0b0;
$guest-old-rooms-heading-bg: #262626;
$guest-old-rooms-item-bg: #0d0d0d;
$guest-room-desc-color: $btn-color; 
$color-decline: #eb0c00;

$guest-profile-label: $room-invite-role-color;
$guest-search-chip: $room-invite-role-color;

$guest-profile-board-name: #dcdddf;
$color-green: #32d74b;

$guest-dashboard-card-bg: #1b1b1b;
$guest-dashboard-role-bg: $thankyou-heading;
$color-grey-5: #d8d8d8;
// ===========================================
// -Variables end
// ===========================================
