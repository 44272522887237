@import '../../../../assets/sass/helpers/variables';

.room-lounge-timer {
  .modal-dialog {
    max-width: 518px;
  }

  .join-lounge-modal-timer {
    font-size: 50px;
    font-weight: 600;
    line-height: normal;
    color: $color-primary;
    text-align: center;
  }

  .modal__heading {
    opacity: 0.9;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: white;
    margin: 24px 0 20px;
    max-width: 419px;
  }

  .modal__desc {
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    color: white;
    max-width: 419px;
    margin-bottom: 38px;
  }

  .modal__footer {
    flex-direction: column;
    margin-top: 0;
  }

  .btn-link {
    font-family: Lato;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: $color-primary;
    border: none;
    background: transparent;
    outline: none;
    padding: 0;
  }

  .btn__themefilled {
    width: 244px !important;
    height: 40px;
    margin-bottom: 32px;
  }
}

.hold-tight-modal {
  .modal-content {
    background: transparent;
    box-shadow: none;
    padding: 0;
    border-radius: 0;
  }

  .hold-tight-icon {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    display: block;
    animation: spin 2.5s ease-in-out infinite;
  }

  .hold-tight-heading {
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    color: white;
    margin: 43px 0 8px;
    text-transform: none;
  }

  .hold-tight-desc {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.38;
    letter-spacing: -0.1px;
    text-align: center;
    color: white;
  }
}

.lounge-invite-expire {
  .modal-dialog {
    max-width: 518px;
  }

  .expired-timer {
    font-size: 50px;
    font-weight: 600;
    line-height: normal;
    color: $color-dark-1;
    text-align: center;
    margin-bottom: 24px;
  }

  .modal__heading {
    opacity: 0.9;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    color: white;
    text-transform: none;
    margin-bottom: 20px;
  }

  .modal__desc {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    color: white;
    max-width: 331px;
    margin: 0 auto;
    margin-bottom: 38px;
  }

  .modal__footer {
    margin-top: 0;
    justify-content: center;

    .btn__themeoutline {
      width: 190px !important;
      height: 40px;
    }
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}