@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.room-invite__table {
  @include mq("sm", max) {
    display: block;
    margin-top: 24px;
  }

  .room-invite__table-head {
    background: $table-head-bg;
    @include mq("sm", max) {
      display: none;
    }
  }

  .room-invite__table-body {
    @include mq("sm", max) {
      display: block;
    }
  }

  .room-invite__table-body-row {
    border-bottom: 1px solid rgba(white, 0.15);
    @include mq("sm", max) {
      display: block;
      position: relative;
      padding-left: 52px;
      padding-right: 66px;
      margin-bottom: 30px;
      border-bottom: none;
    }
  }

  .room-invite__table-cel-head {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.43;
    color: $color-light-grey-1;
    text-transform: uppercase;
    border-top: none;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 24%;

    &--checkbox {
      width: 4%;
    }

    &--members {
      padding-left: calc(47px + 0.75rem);
    }

    // &--blurb{
    //   width: 372px;
    // }

    // &--email{
    //   width: 360px;
    // }

    &--role {
      padding-right: 43px;
    }
  }

  .room-invite__table-cel-body {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: $color-white;
    border-top: none;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    vertical-align: middle;
    opacity: 0.9;
    width: 24%;

    @include mq("sm", max) {
      display: block;
      width: 100%;
      padding: 0;
    }

    &--checkbox {
      vertical-align: unset;
      width: 4%;
      @include mq("sm", max) {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        max-width: 25px;
      }
    }

    &--member {
      vertical-align: top;
    }

    &--blurb {
      font-size: 14px;
      color: $color-grey-3;
      @include mq("sm", max) {
        margin-bottom: 10px;
      }
    }

    &--email {
      vertical-align: top;
      @include mq("sm", max) {
        display: none;
      }
    }

    &--role {
      padding-right: 43px;
      opacity: 1;
      vertical-align: top;
      @include mq("sm", max) {
        padding-right: 0;
      }
    }
  }
}