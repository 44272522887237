@import "../../../assets/sass/helpers/variables";
.dropdown-btn {
  .dropdown-btn__toggle {
    cursor: pointer;
    &::after {
      display: none;
    }
  }
  .dropdown-btn__menu {
    min-width: 187px;
    border-radius: 4px;
    background: $color-dark-1;
    padding: 7px 3px;
    bottom: 8px !important;
    min-height: 50px;
  }
  .dropdown-btn__menu-item {
    color: $color-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding: 7px 0 7px 15px;
    text-align: left;
    position: relative;
    margin-top: 7px;
    &:first-child {
      margin-top: 0;
    }
    &::after {
      content: "";
      height: 1px;
      background: black;
      width: 100%;
      display: block;
      position: absolute;
      bottom: -4px;
    }
    &:last-child::after {
      display: none;
    }
    &:hover {
      background: $color-dark-2;
    }
    &.dropdown-btn__menu-item--error {
      color: $color-red;
    }
  }
  &__menu-icon{
    margin-right: 4px;
  }
}
