.loader{
  display: block;
}
.loader-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #000000e3;
  cursor: pointer;
  z-index: 9999999;
  .spinner-border{
    width: 5rem;
    height: 5rem;
    animation-duration: 1.2s;
  }
  .loader-text{
    margin-top: 5px;
    padding: 0 5px; 
  }

  .loader-inner{
    text-align: center;
  }
}