@import "../../../../assets/sass/helpers/variables";
@import "../../../../assets/sass/helpers/mixins";
.create-room-wrapper {
    max-width: 440px;
    margin: 0 auto;
    padding-top: 45px;
}
.create-room__heading {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.43;
    color: $color-light-grey-1;
    padding: 8px 17px;
    text-transform: uppercase;
    background: $color-dark-3;
    margin-bottom: 24px;
    border-bottom: 1px solid rgba($color-white, 0.5);
}
.comodal__footer {
    padding: 24px 0 0 0;
    border-top: 1px solid rgba($color-white, 0.24);
    text-align: right;
    .br__btn-theme-filled {
        min-width: 93px;
    }
}
.btn__link {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: $color-white;
    margin-right: 32px;
    background: none;
    border: none;
    outline: none;
}

.create-room__form {
    margin-top: 24px;

    .form-group {
        margin-bottom: 24px;
        option{
            background: black;
        }
    }
    .form-label {
        font-size: 14px;
        color: $color-white;
        text-transform: uppercase;
        font-weight: 700;
        line-height: normal;
        opacity: 0.5;
        margin-bottom: 6px;
        display: block;
    }
    .form-control {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $color-white;
        min-height: 24px;
        padding: 6px 8px;
        background: transparent;
        outline: none;
        border-radius: 4px;
        border: solid 1px rgba($color-white, 0.3);
        &::placeholder {
            opacity: 0.8;
        }
        &:focus,
        &.focus {
            outline: none;
            box-shadow: none;
        }
    }
    .label__popover {
        margin-left: 3px;
        cursor: pointer;
    }
    //hiding input type file
    .form-group--file {
        input {
            position: absolute;
            width: 0;
            clip: rect(0,0,0,0);
        }
    }
    // for upload logo
    .form__uploadlogo {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
        padding-bottom: 24px;
        margin-top: 6px;
        border: 2px dashed rgba($color-primary, 0.76);
        .logo__ratio {
            font-size: 50px;
            font-weight: 700;
            color: $color-nero;
            opacity: 0.9;
            line-height: 1.9;
        }
        .logo__drag {
            font-size: 16px;
            line-height: 1.19;
            color: $color-white;
            opacity: 0.9;
            text-align: center;
            font-weight: 700;
            text-transform: capitalize;
            span {
                display: block;
            }
            .linkcolor {
                color: $color-primary;
            }
        }
    }

    .form__textarea {
        min-height: 168px;
    }
    .form-group--badge {
        position: relative;
        input {
            padding-right: 45px;
        }
    }
    .form__badge {
        font-size: 12px;
        color: $color-white;
        opacity: 0.5;
        font-weight: 700;
        line-height: normal;
        position: absolute;
        right: 13px;
        bottom: 12px;
    }
    .form-group--half {
        position: relative;
        margin-bottom: 32px;
        .inner-wrapper {
            position: relative;
            display: flex;
            align-items: center;
        }
        .dollar-sign {
            font-size: 16px;
            font-weight: 700;
            color: $color-white;
            line-height: normal;
            background-color: transparent;
            border: none;
            position: absolute;
            left: 10px;
            bottom: 10px;
        }
        input {
            padding-left: 22px;
            max-width: 132px;
        }
        .form__postapend {
            font-size: 16px;
            font-weight: 700;
            opacity: 0.5;
            color: $color-white;
            line-height: normal;
            margin-left: 10px;
            background: transparent;
            border: none;
            padding: 0;
        }
    }
}

//popover css
.moderator__popover {
    background: $color-dark-1;
    min-width: 186px;
    .head {
        font-size: 10px;
        font-weight: 700;
        line-height: normal;
        color: $color-light-grey-1;
        opacity: 0.6;
        text-transform: uppercase;
        margin-bottom: 2px;
    }
    .popover__role {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: $color-light-grey-1;
        opacity: 0.6;
        margin-bottom: 3px;
    }
    .role__list__item {
        font-size: 12px;
        font-weight: 400;
        line-height: 2;
        color: $color-light-grey-1;
        opacity: 0.6;
        margin-bottom: 2px;
        list-style-type: none;
    }
    > .arrow::after {
        border-top-color: $color-dark-1;
    }
}

// handled full width file input here
.create-room-wrapper {
    .form-group--file {
        label {
            display: block;
        }
    }
    .form__upload-background {
        width: 100%;
        margin-top: 9px;
        height: 100%;
    }
}

// new styles for create room page

.create-room__date-time-wrapper{
    display: flex;
    align-items: flex-start;

    @include mq('sm', max) {
        flex-wrap: wrap;
    }
}
.form-group--date{
    margin-right: 24px;
    input{
        min-width: 199px;
        text-transform: uppercase;
        position: relative;
    }
    input[type="date"]::-webkit-calendar-picker-indicator{
        position: absolute;
        right: 7px;
        top: 6px;
        background: url(../../../../assets/images/ic-calendar.svg) no-repeat;
        transform: rotate(180deg);
    }
}
.form-group--time{
    margin-right: 8px;
    position: relative;
    input{
        min-width: 103px;
        user-select: none;
    }
    &:nth-last-child(1){
        margin-right: 0;
    }
    input[type="time"]::-webkit-calendar-picker-indicator {
        opacity: 0;
        position: absolute;
        right: 0;
        width: calc(100% - 6px);
        user-select: none;
    }
}



// checkbox css
.custom-checkbox-room {
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-bottom: 24px;
  
    /* Hide the browser's default checkbox */
    .custom-checkbox__input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
  
      /* When the checkbox is checked, add a blue background */
      &:checked ~ .custom-checkbox__checkmark {
        background-color: $color-primary;
      
        /* Show the checkmark when checked */
        &:after {
          display: block;
        }
      }
    }
    /* Create a custom checkbox */
    .custom-checkbox__checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: transparent;
      border: 2px solid $color-primary;
      border-radius: 50%;
  
      /* Style the checkmark/indicator */
      &::after {
        left: 7px;
        top: 4px;
        width: 6px;
        height: 12px;
        border: solid black;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
  
        /* Create the checkmark/indicator (hidden when not checked) */
        content: "";
        position: absolute;
        display: none;
      }
    }
    
}
.custom-checkbox__label{
    margin-left: 32px;
    cursor: pointer;
    font-size: 16px;
    color: white;
}
  