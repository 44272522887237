.toast.toast-block{
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  border: none;
  border-radius: 5px;
  font-size: 14px;
  padding: 15px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 999;
  cursor: pointer;
  min-width: 300px;
  transition: all 0.9s ease-in-out;
} 
.toast__body{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.toast__img{
  max-width: 35px;
  height: auto;
  margin-right: 10px;
}
.toast__text{
  font-size: 18px;
}