@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.innercircle {
  max-width: 939px;
  margin: 0 auto;

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 44px;

    @include mq('sm', max) {
      flex-wrap: wrap;
      margin-bottom: 24px;
    }
  }

  &__icon-btn {
    min-width: 92px;
    height: 32px;

    @include mq('sm', max) {
      margin-bottom: 10px;
    }
  }

  &__search-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 457px;
    height: 38px;
    position: relative;

    @include mq('sm', max) {
      width: 100%;
      min-width: 300px;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__search-input {
    padding-right: 40px
  }

  &__search-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
  }

  &__heading {
    font-size: 22px;
    font-weight: 700;
    color: white;
    opacity: 0.9;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(#fff, .5);
  }

  &__list {
    margin-top: 44px;
  }

  &__item {
    background: $guest-dashboard-card-bg;
    border-radius: 4px;
    margin-bottom: 11px;
    padding: 7px 20px;
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-bottom: 11px;
  }

  &__profile-left {
    display: flex;
    align-items: flex-start;
    margin-right: 25px;
    max-width: 283px;
    width: 100%;
  }

  &__profile-img {
    max-width: 50px;
    margin-right: 12px;
  }

  &__profile-name {
    color: white;
    opacity: .9;
    font-weight: bold;
    line-height: 1;
    font-size: 16px;
    margin-top: 9px;
  }

  &__profile-blurb {
    color: #fff;
    opacity: 0.5;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    max-width: 244px;
    margin: 9px 0 4px 0;
  }

  &__profile-right {
    position: absolute;
    right: 15px;
    top: 10px;
  }

  &__dropdown-menu {
    background: red;
  }

  &__dropdown-menu-item {
    padding-left: 13px !important;
  }

  &__modal {
    .modal-dialog {
      max-width: 552px;
      height: 296px;
    }

    .modal-content {
      background: black;
      padding: 58px 67px;
    }
  }

  &__modal-heading {
    color: $color-grey-5;
    opacity: 0.9;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    margin-bottom: 20px;
    text-transform: none;
  }

  &__modal-text {
    color: $color-grey-5;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 33px;
  }

  &__modal-footer {
    display: flex;
    justify-content: center;
  }

  &__btn-outline {
    color: $color-primary;
    font-size: 18px;
    font-weight: normal;
    border: 2px solid $color-primary;
    background: transparent;
    border-radius: 25px;
    padding: 0px 11px;
    width: 150px;
    height: 50px;
  }

  &__btn-filled {
    width: 150px;
    height: 50px;
    min-height: 50px;
    border-radius: 25px !important;
    margin-right: 29px;
    font-size: 18px !important;
  }

  &__not-found{
    text-align: center;
    padding: 10px
  }
}