@import '../../../../assets/sass/helpers/variables';

// checkbox css
.custom-checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;

  /* Hide the browser's default checkbox */
  .custom-checkbox__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    /* When the checkbox is checked, add a blue background */
    &:checked ~ .custom-checkbox__checkmark {
      background-color: $color-primary;
    
      /* Show the checkmark when checked */
      &:after {
        display: block;
      }
    }
  }
  /* Create a custom checkbox */
  .custom-checkbox__checkmark {
    position: absolute;
    top: -9px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid $color-primary;
    border-radius: 50%;

    /* Style the checkmark/indicator */
    &::after {
      left: 7px;
      top: 4px;
      width: 6px;
      height: 12px;
      border: solid black;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      /* Create the checkmark/indicator (hidden when not checked) */
      content: "";
      position: absolute;
      display: none;
    }
  }
}

// dropdown css
.room-invite__dropdown{
  max-width: 230px;
}
.room-invite__dropdown-toggle{
  padding: 6px 11px;
  border: 1px solid rgba(white, 0.3);
  border-radius: 4px;
  color: $room-invite-role-color;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;

  &::after{
    transition: all 0.3s ease-in-out;
  }
  
  &[aria-expanded="true"]::after{
    transform: rotate(180deg);
    border-top-color: white;
  }
  
}
.room-invite__dropdown-menu{
  width: 100%;
  background: $card-body-color;
  border: 1px solid rgba(white, 0.15);
  border-radius: 4px;
}


// radio button css


/* The container */
.custom-radio {
  position: relative;
  cursor: pointer;
  user-select: none;
  color: white;
  font-size: 14px;
  display: block;
  padding: 12px 10px;

  &:hover{
    background: rgba(white, 0.06);
  }
  
  .custom-radio__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    
    &:checked ~ .custom-radio__checkmark {
      background-color: transparent;
      
      &::after {
        display: block;
      }
    }
  }
  
  .custom-radio__checkmark {
    position: absolute;
    top: 10px;
    left: 10px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid $color-primary;
    border-radius: 50%;
    
    &::after {
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $color-primary;
      content: "";
      position: absolute;
      display: none;
    }
  }
  .custom-radio__text{
    margin-left: 35px;
  }
}