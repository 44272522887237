@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.ReactCrop__crop-selection {
  border: 2px solid $color-primary !important;

  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, .5);
  touch-action: none;
  border-image-source: url(data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wA…LJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==);
  border-image-slice: 1;
  border-image-repeat: repeat;
}

.dimension_text {
  height: 100%;
  font-size: 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  font-weight: bold;
  color: rgba(255, 255, 255, 0.40);
}

.theme-modal.img-crop-modal {
  background: rgba(25, 25, 25, 0.769);
  .modal-dialog {
    max-width: 650px;
  }

  .modal-content {
    background: black;
    box-shadow: 0 1px 5px 0 rgba(black, 0.15);
    padding: 32px 32px 45px 32px;
  }

  .theme-modal__body {
    padding: 0 44px;
    @include mq("sm", max) {
      padding: 0;
    }
  }

  .br__create-organization-icon-check {
    max-width: 60px;
    display: block;
    margin: 0 auto;
  }

  .theme-modal-subheading {
    font-size: 24px;
    color: $color-white;
    margin-top: 16px;
    text-transform: none;
    text-align: center;
  }

  .br__create-organization-icon-close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .btn__link{
    color: $color-primary;
  }

}
.img-cro-heading{
  opacity: 0.9;
  color: white;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
  margin-top: 30px;
  text-transform: capitalize;
}
.img-crop-wrapper {
  width: 100%;
  max-height: 341px;
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 1.5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #282828;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #282828;
  }

}

.img-crop-canvas{
  height: 0;
  width: 0;
}

// for blur backgrouond when modal is open
.modal-open {
  .layout {
    background-color: rgba($card-body-color, 0.769);
    filter: blur(3px);
  }
}