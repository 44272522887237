@import "../../../../assets/sass/helpers/variables";
@import "../../../../assets/sass/helpers/mixins";

.room-detail{
  padding-top: 12px;
}
.room-detail__row{
  margin-top: 35px;
  @include mq("sm", max){
    margin-top: 5px;
  }
}
.room-detail__left-col{
  border-right: 2px solid rgba($color-light-grey-4, 0.15);
  padding-right: 0;
  @include mq("sm", max){
    border-right: none;
    padding: 0;
  }
}
.room-detail__right-col{
  @include mq("sm", max){
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
  }
}
.room-detail__attendee-head{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 24px;
}
.room-detail__attendee-count{
  font-size: 16px;
  font-weight: 700;
  color: $color-light-grey-3;
  line-height: normal;
  @include mq("sm", max){
    order: 2;
    display: block;
    width: 100%;
    margin-top: 40px;
  }
}
.room-detail__invite-btn{
  min-width: 168px;
  color: $color-white;
}