@import "../../../assets/sass/helpers/variables";


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px black inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.br__form-group{
  margin-bottom: 24px;
}
.br__form-label{
  font-size: 14px;
  color: $color-white;
  text-transform: uppercase;
  font-weight: 700;
  line-height: normal;
  opacity: 0.5;
  margin-bottom: 6px;
}
.br__input-disabled{
  background-color: #2d2d2d;
}
.br__form-control{
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: $color-white;
  min-height: 24px;
  padding: 6px 8px;
  background: transparent;
  outline: none;
  border-radius: 4px;
  border: solid 1px rgba($color-white, 0.3);
  &::placeholder{
    opacity: 0.8;
  }
  &:focus, &.focus{
    outline: none;
    box-shadow: none;
    color: $color-white;
    background-color: transparent;
    border-color: $color-primary;
    caret-color: $color-primary;
  }
}
.br__form-control--font-18{
  font-size: 18px;
}
.label__popover{
  margin-left: 3px;
  cursor: pointer;
  margin-top: -3px;
  max-width: 18px;
}

.br__input-badge-wrapper{
  position: relative;
}
.br__form-control--badge{
  padding-right: 32px;
}
.br__input-badge{
  position: absolute;
  right: 7px;
  top: 7px;
  cursor: pointer;
}
