@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.guest-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 22px 0 18px 0;
    border-bottom: 1px solid rgba($color-white, 0.1);

    @include mq('sm', max) {
        padding: 41px 20px 18px;
    }

    &__logo {
        max-width: 262px;
        cursor: pointer;

        @include mq('sm', max) {
            max-width: 200px;
        }
    }

    &__beta-logo {
        max-width: 50px;
        margin-right: 10px;
        cursor: pointer;
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__create-link {
        margin-right: 18px;
    }

    &__menu {
        color: $color-white;

        &.show {
            .guest-header__arrow {
                transform: rotate(180deg);
            }
        }

        .guest-header__dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }

    &__media {
        cursor: pointer;
        align-items: center;
    }

    &__user-img {
        margin-right: 10px;
        border-radius: 4px;
        max-height: 40px;
        object-fit: contain;

        @include mq('sm', max) {
            margin-right: 0;
        }
    }

    &__user-avatar-img {
        margin-right: 10px;
        border-radius: 4px;
        height: 40px;
        width: 40px;

        @include mq('sm', max) {
            margin-right: 0;
        }
    }

    &__user-name {
        color: $color-white;
        font-size: 16px;
        line-height: 16px;
        font-weight: 700;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15ch;

        @include mq('sm', max) {
            display: none;
        }
    }

    &__arrow {
        margin-right: 0;
        margin-left: 40px;
        transition: $transition-3ms;

        @include mq('sm', max) {
            display: none;
        }
    }

    &__dropdown-menu {
        top: 7px !important;
        min-width: 192px;
        border-radius: 4px;
        background: $guest-dropdown-bg;
        border: none;
        box-shadow: $guest-dropdown-box-shadow;
        padding: 5px 0px;

        @include mq('sm', max) {
            min-width: 120px;
        }
    }

    &__dropdown-item {
        color: $color-white;
        font-size: 16px;
        font-weight: 400;
        display: block;
        text-align: right;
        padding: 10px 15px;
        border-bottom: 1px solid black;

        &:nth-last-child(1) {
            border-bottom: none;
        }

        &:hover {
            color: $color-white;
            background: transparent;
        }
    }
}
