@import '../../../../assets/sass/helpers/variables';
.theme-modal.br__success-modal {
    .modal-dialog {
        max-width: 518px;
        height: 356px;
    }
    .modal-content {
        background: black;
        box-shadow: 0 1px 5px 0 rgba(black, 0.15);
        padding: 37px 70px 55px 87px;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        position: relative;
        height: 356px;
    }
    .theme-modal__body {
        padding: 0;
    }
    .br__create-organization-icon-check {
        max-width: 60px;
        display: block;
        margin: 0 auto;
    }
    .theme-modal-subheading {
        font-size: 24px;
        color: $color-white;
        margin-top: 16px;
        text-transform: none;
        text-align: center;
    }
    .br__create-organization-icon-close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}

// for blur backgrouond when modal is open
.modal-open {
    .layout {
        background-color: rgba($card-body-color, 0.769);
        filter: blur(3px);
    }
}
