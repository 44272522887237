@import "../../../assets/sass/helpers/variables";

.login_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
}
.login-block {
  max-width: 330px;
  margin: 0 auto;
  @media screen and (max-width: 525px) {
    max-width: 314px;
  }
}
.login__logo {
  max-width: 330px;
  margin-bottom: 88px;
  @media screen and (max-width: 525px) {
    max-width: 314px;
  }
}
.loginform__link {
  font-size: 16px;
  color: $color-primary;
  line-height: normal;
  text-decoration: underline;
  opacity: 0.9;
  transition: $transition-3ms;
  &:hover {
    text-decoration: underline;
    color: $color-primary;
  }
}
.btn_signin {
  display: block;
  width: 100%;
  background: $color-primary;
  border-radius: 25px;
  box-shadow: $btn-box-shadow;
  margin: 44px 0 65px;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  color: $btn-color;
  height: 50px;
}
.disclaimer {
  color: $color-white;
  font-size: 12px;
  opacity: 0.8;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 525px) {
    display: none;
  }
}
.loginform__error {
  color: $color-red;
  font-size: 16px;
  line-height: normal;
  font-weight: normal;
  margin-top: 3px;
}
