@import "../../../assets/sass/helpers/variables";

.br__register-success-heading{
  font-size: 22px;
  font-weight: 700;
  line-height: 1.14;
  opacity: 0.9;
  color: $color-white;
  margin-bottom: 25px;
}
.br__register-success-desc{
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  opacity: 0.9;
  color: $btn-color;
}