@import "../../../../assets/sass/helpers/variables";
@import "../../../../assets/sass/helpers/mixins";
// organization listing
.organization__listing {
  margin-top: 39px;
  .organization__col{
    margin-bottom: 25px;
    @include mq("sm", max) {
      margin-bottom: 20px;
    }
  }
}

// single organization card
.organization__card {
  display: block;
  border: 1px solid rgba($color-light-grey-4, 0.1);
  border-radius: 8px;
  height: 100%;
}
.organization__card-header {
  padding: 29px 20px;
  position: relative;
  min-height: 149px;
  max-height: 149px;
  display: flex;
  align-items: center;
  .theme__dropdown {
    position: absolute;
    top: 12px;
    right: 20px;
  }
  .organization__card-img {
    max-width: 92px;
    max-height: 92px;
    object-fit: contain;
  }
}
.organization__card-body {
  display: block;
  background: $card-body-color;
  padding: 24px 20px 29px;
  border-radius: 0px 0px 7px 7px;
  height: calc(100% - 149px);
  .organization__card-org-name {
    font-size: 24px;
    color: $color-white;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 14px;
    display: inline-block;
    @include mq("sm", max) {
      word-break: break-all;
    }
  }
  .organization__card-body-heading {
    color: $color-grey-1;
    font-size: 12px;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 1px;
    text-transform: uppercase;
  }
  .organization__card-role-names {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: $color-white;
  }
  .organization__card-footer {
    margin-top: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .organization__card-single-info {
    width: 50%;
  }
  .organization__card-single-count {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: $color-white;
  }
}

.br__organization-empty{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 133px);
  .br__organization-empty-img{
    margin-bottom: 31px;
  }
  .br__organization-empty-msg{
    font-size: 16px;
    color: $color-white;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
  }
}
