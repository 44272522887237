@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.organization-detail-wrpper {
    margin-top: 9px;
}

// table css
.organization-detail__table-wrapper {
    margin-top: 25px;
}

.organization__board-listing-table {
    .board-listing__head {
        background: #323232;
    }

    .board-listing__row--head {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }

    .board-listing__cel--head {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.43;
        color: $color-light-grey-1;
        text-transform: uppercase;
        border-top: none;
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .board-listing__row {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

    .board-listing__cel {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $color-light-grey-1;
        border-top: none;
        padding-top: 11px;
        cursor: pointer;

        p {
            margin-bottom: 8px;
        }
    }

    .board-listing__cel--dropdown {
        text-align: right;
        padding-right: 47px;
    }

    .theme__dropdown {
        .theme__dropdownmenu {
            min-width: 172px;
        }
    }
}

.expired-status {
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    opacity: 0.9;
    color: $color-white;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid rgba($color-white, 0.2);
    padding: 2px 4px;
}

.executiveproducer__popover {
    background: #202124;
    min-width: 186px;

    &.executiveproducer__popover--width-400 {
        min-width: 413px;

        @include mq('sm', max) {
            min-width: 340px;
        }
    }

    .head {
        font-size: 10px;
        font-weight: 700;
    }

    > .arrow::after {
        border-top-color: #202124;
    }

    > .arrow::after {
        border-bottom-color: #202124;
    }

    .board-detail__popover-head {
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        color: $color-white;
        margin-bottom: 13px;
    }

    .board-detail__popover-desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.43;
        color: $color-white;
    }

    .board-detail__popover-content {
        padding: 24px;
    }
}

.member-name {
    display: flex;
    align-items: center;

    @include mq('sm', max) {
        font-size: 16px;
        font-weight: 700;
        opacity: 0.9;
        color: white;
        line-height: 1;
    }

    .member-name__img {
        max-width: 40px;
        margin-right: 7px;

        @include mq('sm', max) {
            max-width: 50px;
            margin-right: 12px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    p {
        @include mq('sm', max) {
            margin-bottom: 5px !important;
        }
    }
}

.manage-member-table-wrapper {
    @include mq('sm', max) {
        margin-top: 45px;
    }
}
