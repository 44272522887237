@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.theme-modal {
    .modal-dialog {
        max-width: 575px;
        @include mq('sm', max) {
            max-width: 396px;
        }
    }

    .modal-content {
        background: black;
        box-shadow: 0 1px 5px 0 rgba(black, 0.15);
        padding: 37px 70px 55px 87px;

        @include mq('sm', max) {
            padding: 40px 40px 40px 40px;
        }
    }

    .theme-modal__body {
        padding: 0;
    }

    .comodal__footer {
        padding: 24px 0 0 0;
        border-top: 1px solid rgba($color-white, 0.24);
    }

    .btn__link {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        color: $color-white;
        margin-right: 32px;
        background: none;
        border: none;
        outline: none;
    }

    .theme-modal__heading {
        color: $color-white;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        opacity: 0.9;
    }
}

.theme-modal__form {
    margin-top: 32px;
    @include mq('sm', max) {
        margin-top: 0;
    }

    .form-group {
        margin-bottom: 24px;
    }

    .form-label {
        font-size: 14px;
        color: $color-white;
        text-transform: uppercase;
        font-weight: 700;
        line-height: normal;
        opacity: 0.5;
        margin-bottom: 6px;
    }

    .form-control {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: $color-white;
        min-height: 24px;
        padding: 6px 8px;
        background: transparent;
        outline: none;
        border-radius: 4px;
        border: solid 1px rgba($color-white, 0.3);

        &::placeholder {
            opacity: 0.8;
        }

        &:focus,
        &.focus {
            outline: none;
            box-shadow: none;
        }
        &:disabled {
            background-color: $input-disabled-web;
            @include mq('sm', max) {
                background-color: transparent;
                color: rgba($color-white, 0.2);
            }
        }
    }
    .br__input-disabled {
        background-color: $input-disabled-web;
        @include mq('sm', max) {
            background-color: transparent;
            color: rgba($color-white, 0.2);
        }
    }

    .label__popover {
        margin-left: 3px;
        cursor: pointer;
        margin-top: -2px;
    }

    option {
        background: black;
    }
}

// for blur backgrouond when modal is open
.modal-open {
    .layout {
        background-color: rgba($card-body-color, 0.769);
        filter: blur(3px);
    }
}

.form-group--file__camera-img {
    position: absolute;
    right: -17px;
    bottom: -15px;
    background: black;
}
