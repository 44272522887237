@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?expr5s');
    src: url('fonts/icomoon.eot?expr5s#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?expr5s') format('truetype'), url('fonts/icomoon.woff?expr5s') format('woff'),
        url('fonts/icomoon.svg?expr5s#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-icon'],
[class*=' icon-icon'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-icon-bcast:before {
    content: '\e900';
    color: #fff;
}
.icon-icon-past-rooms:before {
    content: '\e901';
    color: #fff;
}
.icon-icon-light-outline-home:before {
    content: '\e902';
    color: #fff;
}
