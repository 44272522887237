@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';

.verify-email {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;

    &__block {
        max-width: 350px;
        margin: 0 auto;

        @media screen and (max-width: 525px) {
            max-width: 314px;
        }
    }

    &__logos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 53px;
        padding: 22px 0 18px 0;
        &__logo {
            max-width: 259px;
            @media screen and (max-width: 525px) {
                max-width: 314px;
            }
        }
        &__beta-logo {
            max-width: 65px;
            margin-right: 10px;
        }
    }

    &__invite {
        margin-bottom: 16px;
    }

    &__heading {
        text-transform: none;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        color: white;
        margin-bottom: 16px;
    }

    &__info {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        color: white;
        margin-bottom: 61px;
    }

    &__btn-resend {
        display: block;
        width: 100%;
        background: $color-primary;
        border-radius: 25px;
        box-shadow: $btn-box-shadow;
        margin: 0 0 32px;
        font-size: 18px;
        font-weight: 400;
        color: $btn-color;
        height: 40px;
    }

    &__btn-return {
        display: block;
        width: 100%;
        background: transparent;
        border-radius: 25px;
        box-shadow: $btn-box-shadow;
        font-weight: 400;
        font-size: 18px;
        color: $color-primary;
        height: 40px;
        border: 2px solid $color-primary;
    }
}

.disclaimer {
    color: $color-white;
    font-size: 12px;
    opacity: 0.8;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 525px) {
        display: none;
    }
}
