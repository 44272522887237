@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.guest-past-rooms{
  max-width: 939px;
  margin: 0 auto;

  &__top-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 44px;

    @include mq('sm', max) {
      flex-wrap: wrap;
      margin-bottom: 24px;
    }
  }

  &__icon-btn{
    min-width: 92px;
    height: 32px;

    @include mq('sm', max) {
      margin-bottom: 10px;
    }
  }

  &__search-wrap{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__search-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 457px;
    height: 38px;
    margin-right: 14px;
    position: relative;

    @include mq('sm', max) {
      width: 100%;
      min-width: 300px;
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  &__search-input{
    padding-right: 40px
  }

  &__search-icon{
    cursor: pointer;
    position: absolute;
    right: 10px;
  }

  &__heading{
    font-size: 22px;
    font-weight: 700;
    color: white;
    opacity: 0.9;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(#fff, .5);
  }
  
  &__list {
    margin-bottom: 39px;
  }
  
  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid $color-dark-1;
  
    @include mq('sm', max) {
      flex-wrap: wrap;
    }
    
  }

  &__room-type-icon{
    margin-bottom: 14px;
  }
  
  &__room-time {
    color: white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: 700;
  }

  &__recording-img{
    margin-left: 10px;
    margin-top: -3px;
  }
  
  &__room-info {
    display: flex;
    align-items: flex-start;
  
    @include mq('sm', max) {
      margin-bottom: 15px;
    }
  }
  
  &__org-img {
    max-width: 69px;
    margin-right: 13px;
  }
  
  &__room-name {
    color: $guest-dashboard-board-color;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.17;
    text-transform: none;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover{
      color: $color-primary;
    }
  }
  
  &__board-name {
    color: $guest-dashboard-board-color;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.09;
    text-transform: none;
  }

  &__btn-outline {
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
    border: 2px solid $color-primary;
    background: transparent;
    border-radius: 20px;
    padding: 0px 11px;
    margin-bottom: 16px;
    width: 140px;
    height: 35px;
  }

  // advance search filters css starts here
  &__search-filter{
    margin: 10px 0 25px;
    background: $color-dark-1;
    padding: 12px 9px 15px 24px;
    border-radius: 4px;
    border: 1px solid rgba(white, 0.15);

    @include mq('sm', max) {
      padding: 12px 10px 15px 10px;
    }
  }

  &__search-filter-heading{
    color: $data-label-color;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__search-result-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq('sm', max) {
      flex-wrap: wrap;
    }
  }

  &__search-result-list{
    display: flex;
    flex-wrap: wrap;
    max-width: 780px;
  }

  &__search-result-item{
    background: $guest-search-chip;
    padding: 6px 6px 6px 8px;
    border-radius: 16px;
    color: white;
    opacity: 0.5;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    margin-right: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
  }

  &__search-close{
    background: $color-nero;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
  }

  &__clear-label{
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
    line-height: 0.63;
    cursor: pointer;

    @include mq('sm', max) {
      margin-top: 10px;
    }
  }
  // advance search filters css ends here
}
