@import '../../../../assets/sass/helpers/variables';
.theme-modal {
    .modal-dialog {
        max-width: 575px;
    }
    .modal-content {
        background: black;
        box-shadow: 0 1px 5px 0 rgba(black, 0.15);
        padding: 37px 70px 55px 87px;
    }
    .theme-modal__body {
        padding: 0;
    }
    .comodal__footer {
        padding: 24px 0 0 0;
        border-top: 1px solid rgba($color-white, 0.24);
    }
    .btn__link {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        color: $color-white;
        margin-right: 32px;
        background: none;
        border: none;
        outline: none;
    }
    .theme-modal-sub-heading {
        color: $color-white;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        opacity: 0.9;
        text-transform: capitalize;
    }
    .theme-modal-text {
        font-size: 16px;
        line-height: 1.5;
        margin: 32px 0 25px;
        color: $color-white;
    }
}

// for blur backgrouond when modal is open
.modal-open {
    .layout {
        background-color: rgba($card-body-color, 0.769);
        filter: blur(3px);
    }
}

.form-group--file__camera-img {
    position: absolute;
    right: -17px;
    bottom: -15px;
    background: black;
}
