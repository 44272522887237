@import "../../../assets/sass/helpers/variables";
.theme-dropdown {
  .theme-dropdown__toggle {
    cursor: pointer;
    &::after {
      display: none;
    }
  }
  .theme-dropdown__menu {
    min-width: 100px;
    border-radius: 4px;
    background: $color-dark-1;
    padding: 7px 3px;
  }
  .theme-dropdown__menu-item {
    color: $color-white;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    padding: 7px 15px 7px 0;
    text-align: right;
    position: relative;
    margin-top: 7px;
    &:first-child {
      margin-top: 0;
    }
    &::after {
      content: "";
      height: 1px;
      background: black;
      width: 100%;
      display: block;
      position: absolute;
      bottom: -4px;
      left: 0;
    }
    &:last-child::after {
      display: none;
    }
    &:hover {
      background: $color-dark-2;
    }
    &.theme-dropdown__menu-item--error {
      color: $color-red;
    }
    &.theme-dropdown__menu-item--blue {
      color: $color-primary;
    }
  }
  &__menu-icon{
    margin-right: 4px;
  }
}
