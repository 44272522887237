@import '../../../../assets/sass/helpers/variables';
.guest-room-detail {
  padding-top: 12px;

  &__left-col {
    border-right: 2px solid rgba($color-light-grey-4, .15);
  }
  
  &__mid-col {
    border-right: 2px solid rgba($color-light-grey-4, .15);
  }
}

.guest-room-detail-top-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 15px;
}

.guest-room-detail__icon-btn {
  min-width: 92px;
  height: 32px;
}

.rotate-icon {
  transform: rotate(180deg);
  margin-right: 3px;
}

.guest-room-detail__btn-filled {
  height: 32px;
  width: 92px;
  max-height: 32px;
}