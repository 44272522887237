//===========================================
// -Helper Classes start
//===========================================

.center {
  margin: auto;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//===========================================
// -Helper Classes end
//===========================================
