@import '../../../../assets/sass/helpers/variables';

.guest-room-detail {
  &__user-profile {
    height: 100%;
    position: relative;
  }

  //empty css

  &__user-profile-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__user-profile-empty-placeholder {
    max-width: 45px;
    padding: 5px;
    border: 1px solid rgba($color-grey-4, .15);
    margin-bottom: 11px;
  }

  &__user-profile-empty-text {
    display: block;
    width: 100%;
    text-align: center;
    font-style: italic;
    font-size: 14px;
    color: $guest-old-rooms-heading;
  }

  // fill css 

  &__kabob-menu {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
  }

  &__rotate-kabob{
    transform: rotate(90deg);
  }

  &__menu-item{

    &:hover{
      color: $color-primary;
    }
  }

  &__user-profile-img {
    max-width: 136px;
    margin-bottom: 19px;
  }

  &__user-profile-name {
    color: white;
    opacity: 0.9;
    font-size: 20px;
    font-weight: bold;
    line-height: 0.8;
    max-width: 356px;
    text-transform: none;
    margin: 0 auto 9px 0;
  }

  &__user-profile-blurb {
    color: white;
    opacity: 0.5;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.31;
    max-width: 356px;
    margin: 0 auto 3px 0;
  }

  &__user-profile-timestamp {
    opacity: 0.5;
    font-size: 16px;
    font-weight: 400;
    color: $guest-room-desc-color;
    max-width: 356px;
    margin: 0 0 15px;
  }

  &__btn-follow {
    color: $color-primary;
    font-size: 16px;
    font-weight: normal;
    border: 2px solid $color-primary;
    background: transparent;
    border-radius: 20px;
    width: 180px;
    height: 36px;
    transition: $transition-3ms;

    &:hover{
      background: $color-primary;
      color: white;
    }
  }

  &__icon-btn-profile{
    min-width: 144px;
    height: 36px;
    background: $color-primary;
    color: white;
    justify-content: center;
    font-weight: normal;
  }

  &__icon-btn-profile-icon{
    margin-right: 2px;
    max-width: 22px;
  }

  &__user-profile-social {
    margin-top: 45px;
    border-top: 1px solid rgba(white, .1);
    padding-top: 20px;
  }

  &__user-profile-social-text {
    color: $guest-room-desc-color;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 13px;
  }

  &__user-profile-social-img {
    margin-right: 5px;
    width: 32px;
    height: 32px;
  }

  &__user-profile-text {
    color: $guest-room-desc-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 24px;
  }

  &__user-profile-mutual {
    margin-top: 52px;
  }

  &__user-profile-mutual-room-heading {
    font-size: 18px;
    font-weight: bold;
    text-transform: none;
    color: $color-light-grey-3;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid rgba(white, .15);
  }

  &__user-profile-mutual-room-timestamp {
    opacity: 0.5;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }

}
