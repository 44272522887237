@import '../../../assets/sass/helpers/variables';

.br__guest-login-meta {
  max-width: 425px;
}

.guest-login__img-hero {
  max-width: 425px; 
  margin-bottom: 8px;

  @media screen and (max-width: 525px) {
    max-width: 100%;
    height: auto;
  }
}

.guest-login__img-org {
  max-width: 69px;
  margin-right: 13px;
  margin-bottom: 9px;
}

.guest-login__name {
  font-size: 20px;
  opacity: 0.9;
  font-weight: 700;
  line-height: 1.14;
  margin-bottom: 7px;
  color: white;
  text-transform: none;
}

.guest-login__board-name {
  color: $room-invite-role-color;
  opacity: 0.9;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.19;
}

.guest-login__time {
  color: white;
  opacity: 0.5;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
}

.guest-login__desc {
  color: $btn-color;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  max-width: 424px;

  &--truncate {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.guest-login__read-more {
  cursor: pointer;
  color: $color-primary;
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  margin-top: 10px;
}

.guest-login__speakers {
  margin-top: 30px;

  @media screen and (max-width: 525px) {
    margin-top: 50px;
    padding: 0 20px;
  }
}

.guest-login__speaker-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  opacity: 0.8;
  color: $color-white;
}

.guest-login__speakers-list {
  margin-top: 20px;
  padding-right: 15px;
  max-height: 235px;
  overflow-y: auto;
}

.guest-login__speakers-list::-webkit-scrollbar {
  width: 3px;
}

.guest-login__speakers-list::-webkit-scrollbar-track {
  background: transparent;
}

.guest-login__speakers-list::-webkit-scrollbar-thumb {
  width: 3px;
  border-radius: 1.5px;
  background-color: rgba($color-light-grey-3, 0.4);
}

.guest-login__speakers-item {
  display: flex;
  align-items: flex-start;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.guest-login__speaker-info {
  margin-right: 15px;
}

.guest-login__speaker-head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 8px;
}

.guest-login__speaker-name {
  opacity: 0.9;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.2;
  color: $color-white;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.guest-login__speaker-blurb {
  opacity: 0.4;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.29;
  color: $color-white;
}

.guest-login__speaker__role {
  opacity: 0.8;
  font-family: Lato;
  font-size: 11px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.guest-login__info {
  @media screen and (max-width: 525px) {
    padding: 0 20px;
  }
}