@import '../../../assets/sass/helpers/variables';
@import '../../../assets/sass/helpers/mixins';
.layout{
  width: 100%;
}
.layout__container{
  overflow: hidden;
  width: 100%;
  padding: 0px 25px;
  margin-bottom: 20px;
  position: relative;
  @include mq("sm", max) {
    padding: 0;
    margin-bottom: 5px;
  }
}
.layout__content{
  padding: 0px;
  min-height: calc(100vh - 20px);
  max-width: 100%;
  @include mq("sm", max) {
    padding: 0 20px;
  }
}
.layout_min_threshold{
  min-height: 120px;
}