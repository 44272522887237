@import "../../../assets/sass/helpers/variables";

.danger-error-message {
  color: $color-red;
  font-size: 16px;
  line-height: normal;
  font-weight: normal;
  margin-top: 3px;
  text-transform: none;
}
