@import "../../../assets/sass/helpers/variables";
@import "../../../assets/sass/helpers/mixins";
$chip-y-spacing: 15px;
$chip-x-spacing: 5px;
$chip-button-width: $chip-y-spacing + $chip-x-spacing * 2;
$chip-border-radius: 2px;
$chip-background: #555;
$chip-color: #fff;
$chip-min-height: 36px;
$chip-max-height: 60px;

#title {
  letter-spacing: 1px;
}

.chips {
  // max-height: $chip-max-height;
  background: transparent !important;
  // overflow-y: scroll;
}

.chips-input-on-focus-out{
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.chips-input-on-focus-in{
  border: 1px solid #1a74e2;
  border-radius: 3px;
}

.chips-input {
  background: transparent !important;
  color: white;
  display: inline-block;
  width: 33%;
  margin-top: $chip-x-spacing;
  margin-bottom: $chip-x-spacing;
  margin-left: $chip-x-spacing * 2;
  border: 0;
  outline: none;
  //   font-size: 0.9rem;
}

.chip {
  display: inline-block;
  margin-top: $chip-x-spacing;
  margin-bottom: $chip-x-spacing;
  margin-left: $chip-x-spacing;
  margin-right: $chip-button-width;
  position: relative;

  .chip-value {
    display: inline-block;
    padding-left: 7px;
    padding-right: $chip-y-spacing / 2;
    background: $chip-background;
    color: $chip-color;
    border-radius: $chip-border-radius 0 0 $chip-border-radius;
    word-break: break-all;
  }

  .chip-delete-button {
    background: $chip-background;
    color: $chip-color;
    border: 0;
    border-radius: 0 $chip-border-radius $chip-border-radius 0;
    padding: $chip-x-spacing $chip-x-spacing * 2;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -$chip-button-width;
    line-height: 0.5;
    @include mq("sm", max) {
      right: -20px;
    }
  }
}
