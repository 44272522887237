@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers//mixins';

.organization__board-listing-table {
    .board-listing__head {
        background: #323232;
    }

    .board-listing__row--head {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }

    .board-listing__row {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    }

    .board-listing__cel--dropdown {
        text-align: right;
        padding-right: 47px;
    }

    .theme__dropdown {
        .theme__dropdownmenu {
            min-width: 172px;
        }
    }
}

.manage-member-table {
    @include mq('sm', max) {
        display: block;
    }

    .manage-member-table__row {
        @include mq('sm', max) {
            display: block;
            position: relative;
            padding-left: 62px;
            padding-right: 35px;
            padding-bottom: 24px;
            border-bottom: none;
            border-top: none;
        }
    }

    .manage-member-table__cal {
        @include mq('sm', max) {
            display: block;
            width: 100% !important;
            padding: 0;
        }

        &--email {
            @include mq('sm', max) {
                display: none;
            }
        }

        &--blurb {
            @include mq('sm', max) {
                font-size: 14px;
                opacity: 0.5;
                color: white;
                line-height: 1.14;
            }
        }

        &--role {
            @include mq('sm', max) {
                font-size: 12px;
                line-height: 1.67;
                color: $color-light-grey-1;
                margin-bottom: 4px;
            }
        }

        &--dropdown {
            @include mq('sm', max) {
                position: absolute;
                top: 0;
                right: 0;
                display: block;
                padding: 0;
            }
        }
    }
}

.manage-member-table__body {
    @include mq('sm', max) {
        display: block;
    }
}
