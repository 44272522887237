@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.guestroom_wrapper {
    position: relative;
    max-width: 1358px;
    margin: 0 auto;
    padding-top: 72px;
}

.guestroom_logo {
    max-width: 269px;
    margin: 0 auto;
    display: block;
}

.guestroom__information {
    margin-bottom: 47px;
}

.guestroom-detail__col-border {
    border-right: 2px solid rgba($color-light-grey-4, 0.15);
}

.video {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    /* Safari and Chrome */
    -moz-transform: rotateY(180deg);
    /* Firefox */
}

// left col
.guestroom__detail_wrapper {
    max-height: 520px;
    overflow-y: auto;
    max-width: 445px;
}

.guestroom-hero-wrap {
    position: relative;
}

.guestroom-hero-img {
    position: absolute;
    left: 0;
    z-index: 0;
    transition: opacity 1s ease-in-out;
    width: 100%;
    top: 0;
}

.guestroom-video {
    min-height: 238px;
    width: 100%;
    height: 238px;
    background: black;
}

.guestroom-video-show {
    position: relative;
    z-index: 100;
}

.opacity-0 {
    opacity: 0;
}

.guestroom-video-icon {
    position: absolute;
    right: 4px;
    top: 0;
    z-index: 111;
    background: black;
    padding: 5px;
    cursor: pointer;
}

/* width */
.guestroom__detail_wrapper::-webkit-scrollbar {
    width: 3px;
    border-radius: 1.5px;
}

/* Track */
.guestroom__detail_wrapper::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.guestroom__detail_wrapper::-webkit-scrollbar-thumb {
    background: rgba(246, 246, 247, 0.4);
}

/* Handle on hover */
.guestroom__detail_wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(246, 246, 247, 0.4);
}

.hero__cover {
    max-width: 423px;
    margin-bottom: 8px;
}

.hero__profile {
    margin-right: 13px;
    max-width: 69px;
}

.roomdetail__roomname {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    line-height: 1.14;
    opacity: 0.9;
    margin: 0px 0 8px;
    text-transform: none;
}

.roomdetail__board {
    font-size: 14px;
    font-weight: 700;
    color: $room-invite-role-color;
    line-height: 1.09;
    opacity: 0.9;
    text-transform: none;
}

.roomdetail__time {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    opacity: 0.5;
}

.roomdetail__action-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 10px;
    margin: 8px 0 10px;
}

.roomdetail__status-tag {
    margin-right: 10px;
}

.roomdetail__desc {
    font-size: 16px;
    font-weight: 400;
    color: #e4e4e4;
    line-height: 1.5;
    max-width: 430px;
    margin-top: 10px;
}

.roomdetail__desc--truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.accordion__togglebtn {
    padding: 0;
    font-size: 16px;
    color: #d0d0d0;
    font-weight: 700;
    margin-top: 8px;
    text-decoration: underline;
    outline: none;
    box-shadow: none;
}

// right col
.attendees_head {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    opacity: 0.8;
}

.attendees__list {
    margin-top: 23px;
    max-height: 520px;
    overflow-y: auto;
}

/* width */
.attendees__list::-webkit-scrollbar {
    width: 3px;
    border-radius: 1.5px;
}

/* Track */
.attendees__list::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.attendees__list::-webkit-scrollbar-thumb {
    background: rgba(246, 246, 247, 0.4);
}

/* Handle on hover */
.attendees__list::-webkit-scrollbar-thumb:hover {
    background: rgba(246, 246, 247, 0.4);
}

.attendees__item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;

    .attendee__img {
        max-width: 50px;
    }

    .attendee__img--placeholder {
        border: 1px solid rgba(151, 151, 151, 0.15);
        padding: 5px;
    }

    .attendee__handraise {
        position: absolute;
        right: -11px;
        bottom: -9px;
        transform: scaleX(-1);
    }

    .attendee__handraise-noshow {
        clip: rect(0, 0, 0, 0);
    }
}

.attendee__description {
    margin-left: 11px;

    .attendee__name {
        font-size: 16px;
        color: #fff;
        font-weight: 700;
        line-height: 1;
        opacity: 0.9;
        margin-bottom: 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15ch;
    }

    .attendee__blurb {
        font-size: 14px;
        color: #fff;
        font-weight: 700;
        line-height: 1.14;
        opacity: 0.5;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 15ch;
    }
}

.guestroom__leavewrapper {
    margin-top: 8px;
    border-top: 2px solid rgba(224, 224, 224, 0.15);
    padding-top: 29px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn__themeoutline {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    width: 161px;
    height: 40px;
    border: 2px solid $color-primary;
    background: transparent;
    border-radius: 40px;
    line-height: 1;
    transition: $transition-3ms;

    &:hover {
        color: #fff;
        border: 2px solid $color-primary;
        background: transparent;
    }

    &:focus {
        box-shadow: none;
        background: transparent;
        border-color: $color-primary;
        color: #fff;
    }
}

.btn__themefilled {
    background: #1a74e2;
    border-radius: 25px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: #e4e4e4;
    height: 50px;
}

// decline modal css
.decline-promotion__modal {
    border-radius: 0;

    .modal-dialog {
        max-width: 427px;
    }

    .modal-content {
        padding: 50px 40px 40px 40px;
        border: none;
        border-radius: 0;
        background: #000;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    }

    .modal-body {
        padding: 0;
    }

    .decline-modal__heading {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.14;
        color: #fff;
        opacity: 0.9;
        margin-bottom: 20px;
        text-align: center;
        text-transform: none;
    }

    .decline-modal__desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #e4e4e4;
        opacity: 0.9;
        text-align: center;
        margin-bottom: 20px;
    }

    .decline-modal__footer {
        border: none;
        border-radius: 0px;
        margin-top: 22px;
        padding: 0;
        text-align: center;

        > * {
            margin: 0;
        }

        .btn__themefilled {
            width: 156px;
            margin: 0 auto;
        }
    }
}

// modal css
.leaveguestroom__modal {
    border-radius: 0;

    .modal-dialog {
        max-width: 427px;
    }

    .modal-content {
        padding: 50px 40px 40px 40px;
        border: none;
        border-radius: 0;
        background: #000;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    }

    .modal-body {
        padding: 0;
    }

    .modal__heading {
        font-size: 22px;
        font-weight: 700;
        line-height: 1.14;
        color: #fff;
        opacity: 0.9;
        margin-bottom: 20px;
        text-transform: none;
    }

    .modal__desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #e4e4e4;
        opacity: 0.9;
        margin-bottom: 20px;
    }

    .modal__footer {
        border: none;
        border-radius: 0px;
        margin-top: 60px;
        padding: 0;

        > * {
            margin: 0;
        }

        .btn__themeoutline {
            width: 118px;
            margin-right: 20px;
        }

        .btn__themefilled {
            width: 156px;
        }
    }

    .end-modal-footer {
        margin-top: 20px;
        justify-content: center;

        .btn__themefilled {
            height: 40px;
        }

        .endroom-btn-long {
            width: 160px;
            width: 265px;
            margin-top: 13px;
        }
    }
}

// for blur backgrouond when modal is open
.modal-open {
    .layout {
        background-color: rgba(25, 25, 25, 0.77);
        filter: blur(3px);
    }
}

// responsive for mobile
@media screen and (max-width: 575px) {
    .guestroom_wrapper {
        padding-top: 37px;
    }

    .guestroom_logo {
        margin-bottom: 29px;
    }

    .hero__cover {
        max-width: 414px;
        height: 207px;
        object-fit: cover;
    }

    .roomdetail__description_wrapper {
        padding: 20px 20px 0;
    }

    .guestroom__information {
        margin-bottom: 16px;
    }

    .attendees_head {
        display: none;
    }

    .attendees__list {
        margin-top: 0;
        max-height: unset;
        overflow-y: auto;
        padding: 0 89px 0 20px;
    }

    .guestroom__leavewrapper {
        margin-top: 40px;
        border-top: none;
        padding-top: 0;
        text-align: center;
        margin-bottom: 30px;

        .btn__themeoutline {
            width: 314px;
        }
    }

    .leaveguestroom__modal {
        .modal-dialog {
            align-items: flex-end;
            margin: 0;
        }

        .modal-content {
            padding: 40px 50px 50px 50px;
        }

        .modal__desc {
            display: none;
        }

        .modal__footer {
            margin-top: 29px;
            flex-wrap: wrap;

            .btn__themeoutline {
                display: block;
                width: 100%;
                order: 2;
                margin: 0;
            }

            .btn__themefilled {
                display: block;
                width: 100%;
                margin: 0 0 25px 0;
                order: 1;
            }
        }
    }
}

.moderator {
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
    object-fit: contain;
}

.disclaimer-wrapper {
    text-align: right;

    .guestroom__mute-btn {
        cursor: pointer;
    }
}

.disclaimer__text {
    font-size: 14px;
    color: $room-invite-role-color;
    margin-bottom: 12px;
}

.disclaimer__img {
    max-width: 102;
}

// shareable link css
.room-detail {
    &__link-wrapper {
        margin-top: 16px;
    }

    &__link-inner {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        flex-wrap: wrap;
    }

    &__link-input {
        margin-right: 15px;
        max-width: 280px;
        font-weight: 400;
        line-height: 1.5;
        color: white;
        min-height: 24px;
        padding: 6px 8px;
        background: transparent;
        outline: none;
        border-radius: 4px;
        border: solid 1px rgba(white, 0.3);

        &::placeholder {
            opacity: 0.8;
        }

        &:focus,
        &.focus {
            outline: none;
            box-shadow: none;
            background: transparent;
            border: solid 1px rgba(white, 0.3);
            color: inherit;
        }

        @include mq('xl-14', max) {
            max-width: 270px;
        }
    }
}

.room-join-copyBtn {
    position: relative;

    .tool-msg {
        display: none;
    }

    &:hover {
        .tool-msg {
            display: block;
            font-size: 11px;
            font-weight: 500;
            border-radius: 3px;
            color: black;
            background: white;
            width: 118px;
            position: absolute;
            top: -20px;
            left: 0;
            z-index: 100;
            padding: 3px 10px;
        }
    }
}

.recording-text {
    color: $color-decline;
    font-size: 14px;
    font-weight: 400;
}

.recording-modal {
    z-index: 99999;

    &__heading {
        opacity: 0.9;
        font-size: 24px;
        font-weight: 700;
        text-transform: none;
        margin-bottom: 20px;
        color: white;
    }

    &__text {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        max-width: 331px;
        margin: 0 auto;
        margin-bottom: 38px;
    }

    &__btn-okay {
        font-size: 16px;
        color: $color-primary;
        font-weight: 400;
        text-align: center;
        width: 190px;
        height: 40px;
        border: 2px solid $color-primary;
        background: transparent;
        border-radius: 40px;
        margin: 0 auto;
        margin-bottom: 40px;

        &:focus {
            box-shadow: none;
            background: transparent;
            border-color: $color-primary;
            color: $color-primary;
        }
    }

    .btn__themefilled {
        max-width: 190px;
        margin: 0 auto;
        width: 100%;
    }

    &__link {
        display: block;
        color: $color-primary;
        font-size: 16px;
        text-align: center;
    }
}

.invite-text {
    color: $color-decline;
    font-size: 14px;
    font-weight: 400;
}

.invite-modal {
    z-index: 99999;

    &__heading {
        opacity: 0.9;
        font-size: 24px;
        font-weight: 700;
        text-transform: none;
        margin-bottom: 20px;
        color: white;
    }

    &__text {
        color: white;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        max-width: 331px;
        // margin: 0 auto;
        margin-bottom: 38px;
    }

    .invite-modal__footer {
        border-top: none;
        justify-content: center;

        &__btn-okay {
            font-size: 16px;
            color: $color-primary;
            font-weight: 400;
            text-align: center;
            width: 150px;
            height: 40px;
            border: 2px solid $color-primary;
            background: transparent;
            border-radius: 40px;

            &:focus {
                box-shadow: none;
                background: transparent;
                border-color: $color-primary;
                color: $color-primary;
            }
        }

        .btn__themefilled {
            background: #1a74e2;
            border-radius: 25px;
            box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.2);
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            color: #e4e4e4;
            height: 40px;
            width: 150px;
        }
    }

    &__link {
        display: block;
        color: $color-primary;
        font-size: 16px;
        text-align: center;
    }
}

.btn-hand {
    margin-left: 19px;

    &:hover {
        background: $color-primary;
        color: white;
    }
}

.mr-19 {
    margin-right: 19px;
}

.guestroom-detail__countdown {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.guestroom-detail__countdown-text {
    color: $thankyou-heading;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 17px;
}

.guestroom-detail__countdown-time {
    color: $card-body-color;
    font-size: 60px;
    font-weight: bold;
    line-height: normal;
}

.guestroom-video-btn {
    min-width: 170px;
    justify-content: center;
    margin-right: 20px;
}

.guestroom-video-btn-icon {
    margin-right: 17px;
}

.guestroom__disclaimer-wrapper {
    display: flex;
    flex-wrap: wrap;
}

.item-blurb-hidden {
    margin-top: 4px;
    line-height: 1.29;
    font-size: 14px;
    transform: scale(0);
    opacity: 0;
    clip: rect(0, 0, 0, 0);
}

.h-40{
    height: 40px;
}