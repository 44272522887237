@import '../../../../assets/sass/helpers/variables';
@import '../../../../assets/sass/helpers/mixins';

.room-detail__speakers-item {
    display: flex;
    align-items: flex-start;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(white, 0.15);

    .theme__dropdown {
        margin-left: auto;

        .theme__dropdownmenu {
            min-width: 210px;
        }
    }
}

.room-detail__drag-drop {
    transform: rotate(90deg);
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 14px;
}

.room-detail__speaker-info {
    margin-right: 15px;
    flex: 1;
}

.room-detail__speaker-img {
    width: 64px;
    height: 64px;
    object-fit: contain;
}

.room-detail__speaker-head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 8px;
    @include mq('sm', max) {
        flex-wrap: wrap;
    }
}

.room-detail__speaker-name {
    opacity: 0.9;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2;
    color: $color-white;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-all;
    text-transform: capitalize;
}

.room-detail__speaker-name-span {
    overflow: hidden;
    max-width: 15ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
}

.room-detail__icon-identity {
    margin-left: 5px;
}

.room-detail__speaker-blurb {
    opacity: 0.4;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
    color: $color-white;
    overflow: hidden;
    max-width: 15ch;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
}

.room-detail__speaker-role {
    opacity: 0.8;
    font-family: Lato;
    font-size: 11px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-left: 5px;
    @include mq('sm', max) {
        display: block;
        width: 100%;
    }
}
