//===========================================
// -Buttons start
//===========================================

.btn {
  cursor: pointer;
  &[disabled],
  &.disabled {
    cursor: not-allowed;
  }
}

.br__btn-theme-filled{
  background: $color-primary;
  border-radius: 20px;
  box-shadow: $btn-box-shadow;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: $btn-color;
  height: 40px;
  display: inline-block;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  border: none;

  &--width-231{
    width: 231px;
  }
  &--width-93{
    width: 93px;
  }
  &--width-181{
    width: 181px;
  }
  &--width-138{
    width: 138px;
  }
}
//===========================================
// -Buttons end
//===========================================
   